import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface CohortFeedItem {
  id: number;
  cohortId: number;
  userId: number;
  text: string;
  stickyDays: number;
  likeCount: number;
  commentCount: number;
  createdAt: string;
  updatedAt: string;
  user?: {
    firstName: string;
    lastName: string;
  };
};

export const blankCohortFeedItem: CohortFeedItem = {
  id: 0,
  likeCount: 0,
  commentCount: 0,
  stickyDays: 0,
  cohortId: 0,
  userId: 0,
  text: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type CohortFeedItemsStore = typeof initialState;

const cohortFeedItems = (state = initialState, action: any): CohortFeedItemsStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default cohortFeedItems;
