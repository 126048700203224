import api from './api';
import { ATTEMPTING_LOGIN, ATTEMPTING_PASSWORD_RESET, ATTEMPTING_PASSWORD_RESET_REQUEST, ATTEMPTING_REGISTRATION, ATTEMPTING_VERIFY_TOKEN, COMPLETED_LOGIN, COMPLETED_PASSWORD_RESET, COMPLETED_PASSWORD_RESET_REQUEST, COMPLETED_REGISTRATION, COMPLETED_VERIFY_TOKEN, FAILED_LOGIN, FAILED_PASSWORD_RESET, FAILED_PASSWORD_RESET_REQUEST, FAILED_REGISTRATION, FAILED_VERIFY_TOKEN, LOGGED_IN, LOGGED_OUT, LOGGING_IN, LOGGING_OUT, FAILED_RESEND_VERIFICATION_TOKEN, COMPLETED_RESEND_VERIFICATION_TOKEN, ATTEMPTING_RESEND_VERIFICATION_TOKEN } from './loginReducer';

export function postLogout() {
  return async (dispatch: (action: any) => void) => {
    dispatch({ type: LOGGING_OUT });

    try {
      await api.logout();
      dispatch({ type: LOGGED_OUT });
    } catch (error) {
      console.warn(`Error logging out`, error);
    }
  };
}

/// Doesn't issue the server call
export function alreadyLoggedOut() {
  return (dispatch: (action: any) => void) => {
    dispatch({ type: LOGGING_OUT });
    dispatch({ type: LOGGED_OUT });
  };
}


export function postRegistration(email: string, password: string, firstName: string, lastName: string) {
  return async (dispatch: (action: any) => void) => {
    dispatch({ type: ATTEMPTING_REGISTRATION });

    try {
      const user = await api.service('users').create({
        email,
        password,
        firstName,
        lastName,
      });

      dispatch({ type: COMPLETED_REGISTRATION, email, password, user });
    } catch (error) {
      dispatch({ type: FAILED_REGISTRATION, error });
    }
  };
}

export function postLogin(email: string, password: string) {
  return async (dispatch: (action: any) => void) => {
    dispatch({ type: ATTEMPTING_LOGIN });

    try {
      await api.authenticate({
        strategy: 'local',
        email,
        password,
      });

      const { user, accessToken } = await api.get('authentication');

      dispatch({ type: COMPLETED_LOGIN, user, token: accessToken });
      dispatch({ type: LOGGING_IN });
      dispatch({ type: LOGGED_IN})
    
    } catch (error) {
      alert(String(error));
      dispatch({ type: FAILED_LOGIN, error });
    }
  };
}

export function postResendVerifyToken(email: string) {
  return async (dispatch: (action: any) => void) => {
    dispatch({ type: ATTEMPTING_RESEND_VERIFICATION_TOKEN });

    try {
      await api.service('user-verification').create({
        action: 'resend-verification',
        email,
      });

      dispatch({ type: COMPLETED_RESEND_VERIFICATION_TOKEN, email });
    } catch (error) {
      dispatch({ type: FAILED_RESEND_VERIFICATION_TOKEN, error });
    }
  }
}

export function postVerifyToken(token: string) {
  return async (dispatch: (action: any) => void) => {
    dispatch({ type: ATTEMPTING_VERIFY_TOKEN });

    try {
      await api.service('user-verification').create({
        action: 'verify-token',
        token,
      });

      dispatch({ type: COMPLETED_VERIFY_TOKEN, token });
    } catch (error) {
      dispatch({ type: FAILED_VERIFY_TOKEN, error });
    }
  }
}

export function postPasswordResetRequest(email: string) {
  return async (dispatch: (action: any) => void) => {
    dispatch({ type: ATTEMPTING_PASSWORD_RESET_REQUEST });

    try {
      await api.service('user-verification').create({
        action: 'send-password-reset',
        email,
      });

      dispatch({ type: COMPLETED_PASSWORD_RESET_REQUEST, email });
    } catch (error) {
      dispatch({ type: FAILED_PASSWORD_RESET_REQUEST, error });
    }
  }
}

export function postPasswordReset(token: string, newPassword: string) {
  return async (dispatch: (action: any) => void) => {
    dispatch({ type: ATTEMPTING_PASSWORD_RESET });

    try {
      await api.service('user-verification').create({
        action: 'reset-password',
        token,
        newPassword,
      });

      dispatch({ type: COMPLETED_PASSWORD_RESET, token });
    } catch (error) {
      dispatch({ type: FAILED_PASSWORD_RESET, error });
    }
  }
}
