import React, { Component, FormEvent } from 'react';
import { Alert, Button, Card, CardBody, Col, Modal, Row } from 'reactstrap';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import PageTitle, { RouteItem } from '../components/PageTitle';
import Spinner from '../components/Spinner';
import { WorkingOverlay } from './LoadingOverlay';


export function htmlFieldValue(element: HTMLInputElement) {
  if (element.type === 'checkbox') {
    return element.checked;
  }

  return element.value;
}

export interface FormCallbacks<Type> {
  getField: (name: keyof Type, ifMissing?: any) => any;
  changeField: (name: keyof Type, event: FormEvent | string, convert?: (value: any) => any) => void;
  changeFileField: (name: keyof Type, event: FormEvent<HTMLInputElement>) => void;
  changeFieldValue: (name: keyof Type, event: FormEvent<any> | null, value: any) => void;
  isEditing: () => boolean;
}

interface Props<Type> {
  route: RouteItem[];
  isNew: boolean;
  original: Type | undefined;
  titleField: keyof Type;
  titleIfNew: string;
  renderForm: (callbacks: FormCallbacks<Type>) => any;
  renderPageLinks?: () => any;
  onSave: (detailScreen: DetailScreen<Type>, original: Type, changes: Partial<Type>) => Promise<Type>;
  onDelete: (detailScreen: DetailScreen<Type>, original: Type) => Promise<void>;
  extraButtons?: any;
}

interface State {
  editing: boolean;
  edited: any;
  confirmingDelete: boolean;
  working: boolean;
  error?: string;
}

export default class DetailScreen<Type> extends Component<Props<Type>, State> {
  callbacks: FormCallbacks<Type>;

  constructor(props: Props<Type>) {
    super(props);

    this.callbacks = {
      getField: (name, ifMissing = '') => this.getField(this.getOriginal(), name, ifMissing),
      changeField: (name, event, convert) => this.changeField(this.getOriginal(), name, event, convert),
      changeFieldValue: (name, event, newValue) => this.changeFieldValue(this.getOriginal(), name, event, newValue),
      changeFileField: (name, event) => this.changeFileField(this.getOriginal(), name, event),
      isEditing: () => this.isEditing(),
    };

    this.state = {
      editing: props.isNew,
      edited: {},
      confirmingDelete: false,
      working: false,
    };
  }

  getOriginal = (): Type => this.props.original || ({} as Type);

  toggleEdit = (event: FormEvent) => {
    event.preventDefault();
    if (this.state.editing) {
      // TODO: an "are you sure" warning would be user friendly here
      this.setState({ editing: !this.state.editing, edited: {} });
    } else {
      this.setState({ editing: true });
    }
  }

  isEditing = (): boolean => this.state.editing;

  getField = (original: any, field: keyof Type, ifMissing: any): any => {
    if (field in this.state.edited) {
      const stringField = `${field}__string`;
      if (stringField in this.state.edited) {
        return this.state.edited[stringField];
      }

      return this.state.edited[field];
    }

    return field in original ? original[field] : ifMissing;
  }

  changeFileField = (_original: any, field: keyof Type, event: FormEvent<HTMLInputElement>) => {
    if (!this.state.editing) {
      event.preventDefault();
    } else {
      const files = event.currentTarget.files;
      this.setState({
        edited: {
          ...this.state.edited,
          [field]: files,
        },
      });
    }
  }

  changeField = (_original: any, field: keyof Type, event: FormEvent<any> | string, convert?: (value: any) => any) => {
    if (!this.state.editing) {
      if (typeof (event) !== 'string') {
        event.preventDefault();
      }
    } else {
      const newValue = typeof (event) === 'string' ? event : htmlFieldValue(event.currentTarget);
      if (convert) {
        try {
          const convertedValue = convert(newValue);
          this.setState({
            edited: {
              ...this.state.edited,
              [field]: convertedValue,
              [`${field}__string`]: newValue,
              [`${field}__error`]: false,
            }
          });
          console.log(`Accepted edit`);
        } catch (error) {
          this.setState({
            edited: {
              ...this.state.edited,
              [`${field}__string`]: newValue,
              [`${field}__error`]: true,
            }
          });
          console.log(`Failed edit`);
        }
      } else {
        this.setState({
          edited: {
            ...this.state.edited,
            [field]: newValue,
          }
        });
      }
    }
  }

  changeFieldValue = (_original: any, field: keyof Type, event: FormEvent<any> | null, newValue: any) => {
    if (!this.state.editing) {
      if (event) {
        event.preventDefault();
      }
    } else {
      this.setState({ edited: { ...this.state.edited, [field]: newValue } });
    }
  }

  onSavePressed = async (event: FormEvent) => {
    event.preventDefault();

    if (this.props.onSave) {
      const original = this.getOriginal();

      this.setState({ working: true, error: undefined });

      try {
        await this.props.onSave(this, original, this.state.edited);

        this.setState({ working: false, editing: false, edited: {} });

      } catch (error) {
        // Don't stop editing
        this.setState({
          working: false,
          error: String(error),
        });
      }
    }
  }

  onDeletePressed = (_event: FormEvent) => {
    this.setState({ confirmingDelete: true });
  }

  onCancelDeletePressed = (event?: React.FormEvent<HTMLButtonElement>) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    this.setState({ confirmingDelete: false });
  }

  onConfirmDeletePressed = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState({
      working: true,
      error: undefined,
      confirmingDelete: false,
    });

    try {
      await this.props.onDelete(this, this.getOriginal());

      this.setState({
        editing: false,
        edited: {},
        working: false,
      });
    } catch (error) {
      // Don't stop editing
      this.setState({
        working: false,
        error: String(error),
      });
    }
  }

  render() {
    const { working, editing, confirmingDelete, error } = this.state;

    return (
      this.props.original
        ? <>
          <PageTitle
            title={this.getField(this.getOriginal(), this.props.titleField, '') || this.props.titleIfNew}
            route={this.props.route}
            renderPageLinks={this.props.renderPageLinks}
          >
            <div className="float-right">
              {this.props.extraButtons}
              <Button
                className="ml-2"
                color={editing ? "secondary" : "primary"}
                onClick={this.toggleEdit}
              >
                {editing ? "Cancel" : "Edit"}
              </Button>
              {editing
                ? <Button className="ml-2" color="success" onClick={this.onSavePressed}>Save</Button>
                : null}
            </div>
          </PageTitle>
          <WorkingOverlay working={working}>
            <Row>
              <Col xs={12}>
                {error && <Alert color="danger">{error}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {this.props.renderForm(this.callbacks)}
                    {editing && !this.props.isNew && this.props.onDelete
                      ? <Button className="mr-2" color="danger" onClick={this.onDeletePressed}>Delete</Button>
                      : null
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </WorkingOverlay>
          <Modal centered isOpen={confirmingDelete}>
            <ModalHeader toggle={this.onCancelDeletePressed}>Confirm Deletion</ModalHeader>
            <ModalBody>Are you sure you want to delete this record?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.onCancelDeletePressed}>Cancel</Button>
              <Button color="danger" onClick={this.onConfirmDeletePressed}>Delete</Button>{' '}
            </ModalFooter>
          </Modal>
        </>
        : <Spinner />
    );
  }
}

