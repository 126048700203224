import api from './api';
import { blankCohortMember, CohortMember } from './cohortMembersReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveCohortMember(
  original: CohortMember,
  changes: Partial<CohortMember>
): Promise<CohortMember> {
  const cohortMember: CohortMember = { ...blankCohortMember, ...original, ...changes };
  const params = {
    query: {
      $admin: true,
    },
  };

  const newRecord = cohortMember.id
    ? await api.service('cohort-members').patch(cohortMember.id, changes, params)
    : await api.service('cohort-members').create(cohortMember, params);
  notifyUpdateListeners('cohort-members');
  return newRecord;
}

export async function deleteCohortMember(original: CohortMember) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('cohort-members').remove(original.id, params);
  notifyUpdateListeners('cohort-members');
}

