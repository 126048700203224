import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface Course {
  id: number;
  title: string;
  uploadGroupIds: string;
  createdAt: string;
  updatedAt: string;
};

export const blankCourse: Course = {
  id: 0,
  title: '',
  uploadGroupIds: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type CoursesStore = typeof initialState;

const courses = (state = initialState, action: any): CoursesStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default courses;
