import { useEffect, useState } from 'react';
import api from '../state/api';
import { AppSetting, blankAppSetting } from '../state/appSettingsReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchAppSettingsResults {
  appSettings?: AppSetting[];
  numberOfPages: number;
  numberOfAppSettings?: number;
  totalNumberOfAppSettings?: number;
};

interface FetchAppSettingsProps {
  children: (results: FetchAppSettingsResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchAppSettings = ({ children, page, search, query }: FetchAppSettingsProps): JSX.Element => {
  const [results, setResults] = useState<FetchAppSettingsResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('app-settings');

  useEffect(() => {
    let cancelled = false;

    api.service('app-settings').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfAppSettings: json.total,
            numberOfPages: json.total / json.limit,
            numberOfAppSettings: json.data.length,
            appSettings: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching appSettings: `, error);
        }
      })

    return () => { cancelled = true; };
  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchAppSettingProps {
  children: ({ appSetting }: { appSetting: AppSetting }) => any;
  id?: number;
};

export const FetchAppSetting = ({ children, id }: FetchAppSettingProps): JSX.Element => {
  const [appSetting, setAppSetting] = useState<AppSetting>({ ...blankAppSetting });
  const lastRefreshed = useUpdateListener('app-settings');

  useEffect(() => {
    if (!id || !+id) {
      setAppSetting(blankAppSetting);
      return;
    }

    let cancelled = false;

    api.service('app-settings').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setAppSetting(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching appSettings: `, error);
        }
        setAppSetting({ ...blankAppSetting });
      });

    return () => { cancelled = true; };
  }, [id, lastRefreshed]);

  return children({ appSetting });
};

