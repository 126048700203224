import React, { Component } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

import icon from '../media/icon.png';

export interface Screen {
  path: string;
  component: any;
  isAdmin?: boolean;
  bottom?: boolean;
};

interface Props {
  titleLink: string;
  screens: Screen[];
  onLogOutPressed?: () => void;
  isUserAnAdmin?: boolean;
}

class Sidebar extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDropdownOpen: false
    };
  }

  toggleDropdown = () => {
    this.setState({ isDropdownOpen: true });
  }

  screenLink({ to, children }: { to: string, children: any }) {
    return (
      <NavItem>
        <NavLink exact activeClassName="active" tag={RouterNavLink} to={to}>{children}</NavLink>
      </NavItem>
    );
  }

  render() {
    const { isUserAnAdmin, titleLink, onLogOutPressed, screens } = this.props;

    return (
      <nav id="sidebar">
        <Nav vertical>
          <NavItem>
            <NavLink className="mt-3 mb-3 brand-logo" activeClassName="active" tag={RouterNavLink} exact to={titleLink}>
              <div style={{ paddingRight: '4px' }}>
                <img src={icon} height={128} alt="Logo" />
              </div>
            </NavLink>
          </NavItem>
          {screens.map(screen => screen.path.indexOf(':') < 0 && (!screen.isAdmin || isUserAnAdmin)
            ? <this.screenLink key={screen.path} to={screen.path}>{screen.component.title}</this.screenLink>
            : null)}
        </Nav>
        {onLogOutPressed &&
          <Nav vertical className="nav-align-bottom">
            {/* <this.screenLink to="/changepassword">
              Change Password
            </this.screenLink> */}
            <NavItem>
              <NavLink onClick={onLogOutPressed}>Sign Out</NavLink>
            </NavItem>
          </Nav>
        }
      </nav>
    );
  }
}

export default Sidebar;
