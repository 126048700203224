import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DateTimeInput from '../components/DateTimeInput';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { FetchHabit } from '../fetchers/FetchHabits';
import UserSelect from '../selects/UserSelect';
import { Habit } from '../state/habitsReducer';
import { saveHabit, deleteHabit } from '../state/habitsActions';

interface ParamProps {
  habitid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class HabitDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField, changeFieldValue }: FormCallbacks<Habit>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="userId">User</Label>
              <UserSelect
                id="userId"
                value={getField('userId')}
                onChange={newValue => changeFieldValue('userId', null, newValue)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input type="text" name="title" id="title" value={getField('title')} onChange={event => changeField('title', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="question">Queston</Label>
              <Input type="text" name="question" id="question" value={getField('question')} onChange={event => changeField('question', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="color">Colour</Label>
              <Input type="text" name="color" id="color" value={getField('color')} onChange={event => changeField('color', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="repeat">Repeat</Label>
              <Input type="text" name="repeat" id="repeat" value={getField('repeat')} onChange={event => changeField('repeat', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="reminderTime">Next Reminder</Label>
              <DateTimeInput type="text" name="reminderTime" id="reminderTime" value={getField('reminderTime')} onChange={newValue => changeFieldValue('reminderTime', null, newValue)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="monthlyScore">Monthly Score</Label>
              <Input type="text" name="monthlyScore" id="monthlyScore" value={getField('monthlyScore')} onChange={event => changeField('monthlyScore', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="checkedDays">Checked Days (JSON)</Label>
              <Input type="text" name="checkedDays" id="checkedDays" value={getField('checkedDays')} onChange={event => changeField('checkedDays', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup check>
              <Label check for="isArchived">
                <Input type="checkbox" name="isArchived" id="isArchived" checked={getField('isArchived')} onChange={event => changeField('isArchived', event)} />
                {' '}Archived?
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<Habit>, original: Habit, changes: Partial<Habit>): Promise<Habit> => {
    return await saveHabit(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<Habit>, original: Habit): Promise<void> => {
    return await deleteHabit(original);
  }

  render() {
    return (
      <FetchHabit id={+this.props.match.params.habitid}>
        {({ habit }) =>
          <DetailScreen<Habit>
            original={habit}
            isNew={this.props.match.params.habitid === 'new'}
            titleField="title"
            titleIfNew="New Habit"
            route={[{ 'Habits': '/habits' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchHabit>
    );
  }
}

export default HabitDetailScreen;
