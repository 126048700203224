import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { Button, Card, CardFooter, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FormErrorText from '../components/FormErrorText';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { LogoCardBody, LogoCardHeader } from '../components/LogoCardHeader';
import { postRegistration } from '../state/loginActions';
import { CombinedStore } from '../state/rootReducer';

interface StateProps {
  registrationState: string,
};

interface DispatchProps {
  postRegistration: (email: string, password: string, firstName: string, lastName: string) => void;
};

interface Props {
};

type CombinedProps = Props & StateProps & DispatchProps & RouteComponentProps<{}>;

interface State {
  email: string;
  password: string;
  password2: string;
  firstName: string;
  lastName: string;
  posted: boolean;
  errors: any;
};

class RegisterScreen extends Component<CombinedProps, State> {
  constructor(props: CombinedProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      password2: '',
      firstName: '',
      lastName: '',
      posted: false,
      errors: {},
    };
  }

  onEmailChanged = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ email: event.currentTarget.value });
  }

  onPasswordChanged = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password: event.currentTarget.value });
  }

  onPassword2Changed = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password2: event.currentTarget.value });
  }

  onFirstNameChanged = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.currentTarget.value });
  }

  onLastNameChanged = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ lastName: event.currentTarget.value });
  }

  onRegisterPressed = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const errors: any = {};

    if (this.state.email.indexOf('@') < 1) {
      errors['email'] = "Please enter an email address";
    }
    if (this.state.password.length < 8) {
      errors['password'] = "Your password must be at least 8 characters";
    } else if (this.state.password2 !== this.state.password) {
      errors['password2'] = "Passwords do not match";
    }

    if (this.state.firstName.trim() === '') {
      errors['firstName'] = "We need your full name";
    }
    if (this.state.lastName.trim() === '') {
      errors['lastName'] = "We need your full name";
    }

    this.setState({ errors, posted: true });
    if (_.keys(errors).length > 0) {
      return;
    }

    this.props.postRegistration(this.state.email, this.state.password, this.state.firstName, this.state.lastName);
  }

  componentDidUpdate(prevProps: CombinedProps) {
    if (prevProps.registrationState !== this.props.registrationState) {
      if (this.props.registrationState === 'logged_in') {
        // this.props.onLoggedIn(this.props.loginReturnTo);
        return;
      }
    }
  }

  onTryAgain = (event: React.FormEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ posted: false, errors: {} });
  }

  render() {
    return (
      <Container className="mt-5 mb-5 RegisterScreen">
        <Row className="justify-content-center">
          <Col xs="auto">
            <Card className="position-relative">
              <LogoCardHeader />
              <LogoCardBody className="p-4">
                {this.state.posted && this.props.registrationState === 'failed'
                  ? (
                    <>
                      <p>We were unable to complete your registration request.
                            You may <a href="/" onClick={this.onTryAgain}>try again</a>.
                          </p>
                    </>
                  ) : (
                    this.state.posted && this.props.registrationState === 'complete'
                      ? (
                        <>
                          <h4>Thanks for registering!</h4>
                          <p>You may now <Link to="/login">sign in</Link>.</p>
                        </>
                      ) : (
                        <>
                          <h4 className="text-center">Create your account</h4>
                          <Form>
                            <FormGroup>
                              <Label for="firstName">First name</Label>
                              <Input type="text" name="firstName" onChange={this.onFirstNameChanged} value={this.state.firstName} />
                              <FormErrorText error={this.state.posted && this.state.errors.firstName} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="lastName">Last name</Label>
                              <Input type="text" name="lastName" onChange={this.onLastNameChanged} value={this.state.lastName} />
                              <FormErrorText error={this.state.posted && this.state.errors.lastName} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="email">Email</Label>
                              <Input type="email" name="email" onChange={this.onEmailChanged} value={this.state.email} />
                              <FormErrorText error={this.state.posted && this.state.errors.email} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="password">Password</Label>
                              <Input type="password" name="password" onChange={this.onPasswordChanged} value={this.state.password} />
                              <FormErrorText error={this.state.posted && this.state.errors.password} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="password2">Password again, to confirm</Label>
                              <Input type="password" name="password2" onChange={this.onPassword2Changed} value={this.state.password2} />
                              <FormErrorText error={this.state.posted && this.state.errors.password2} />
                            </FormGroup>
                          </Form>
                          <div className="text-center mt-4">
                            <Button color="primary" onClick={this.onRegisterPressed}>Register</Button>
                          </div>
                        </>
                      )
                  )}
              </LogoCardBody>
              <CardFooter className="text-center">
                Already have an account? <Link to="/login">Sign in here</Link>
              </CardFooter>
              {this.props.registrationState === 'in_progress' && <LoadingOverlay />}
            </Card>
          </Col>
        </Row>
      </Container >
    );
  }
}

const mapStateToProps = (state: CombinedStore): StateProps => ({
  registrationState: state.login.registrationState,
});

const mapDispatchToProps = (dispatch: (action: any) => void): DispatchProps => ({
  postRegistration: (email, password, firstName, lastName) => dispatch(postRegistration(email, password, firstName, lastName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterScreen));
