import api from './api';
import { blankHabit, Habit } from './habitsReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveHabit(original: Habit, changes: Partial<Habit>): Promise<Habit> {
  const habit: Habit = { ...blankHabit, ...original, ...changes };

  const params = {
    query: {
      $admin: true,
    },
  };

  const newRecord = habit.id
    ? await api.service('habits').patch(habit.id, changes, params)
    : await api.service('habits').create(habit, params);
  notifyUpdateListeners('habits');
  return newRecord;
}

export async function deleteHabit(original: Habit) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('habits').remove(original.id, params);
  notifyUpdateListeners('habits');
}
