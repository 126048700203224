import React, { Component } from 'react';
import PageTitle from '../components/PageTitle';


interface Props {

};

class DashboardScreen extends Component<Props> {
  static title = "Home";

  render() {
    return (
      <>
        <PageTitle title={DashboardScreen.title}>
        </PageTitle>
      </>
    );
  }
}

export default DashboardScreen;
