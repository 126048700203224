import React from 'react';
import Select, { OptionTypeBase } from 'react-select';
import AsyncSelect from 'react-select/async';

export const StyledSelect = <OptionType extends OptionTypeBase = { label: string; value: string }>(props: Select<OptionType>['props']): JSX.Element =>
  <Select<OptionType>
    className="bootstrap-react-select"
    classNamePrefix="bootstrap-react-select"
    {...props}
  />;

export const StyledAsyncSelect = <OptionType extends OptionTypeBase = { label: string; value: string }>(props: AsyncSelect<OptionType>['props']): JSX.Element =>
  <AsyncSelect<OptionType>
    className="bootstrap-react-select"
    classNamePrefix="bootstrap-react-select"
    {...props}
  />;

export interface SelectOption {
  value: string;
  label: string;
}
