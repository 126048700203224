import React from 'react';
import LoadingIndicator from './LoadingIndicator';

interface Props {
  className?: string;
}

interface State {
}

export class LoadingOverlay extends React.Component<Props, State> {
  render() {
    return (
      <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: 'flex', backgroundColor: '#ffffff80' }}>
        <LoadingIndicator />
      </div>
    );
  }
}

export const WorkingOverlay = ({ children, working }: { children: any; working: boolean }): JSX.Element => {
  return (
    <>
      {children}
      {working && <LoadingOverlay />}
    </>
  )
};
