import React from 'react';
import './LoadingIndicator.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

interface State {
}

export default class LoadingIndicator extends React.Component<Props, State> {
  render() {
    return (
      <div className={classNames(this.props.className, 'LoadingIndicator', 'loading')}>
        <div className="loading-indicator loading-indicator1"/>
        <div className="loading-indicator loading-indicator2"/>
        <div className="loading-indicator loading-indicator3"/>
      </div>
    );
  }
}
