import api from './api';
import { blankCourse, Course } from './coursesReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveCourse(original: Course, changes: Partial<Course>): Promise<Course> {
  const course: Course = { ...blankCourse, ...original, ...changes };

  const newRecord = course.id
    ? await api.service('courses').patch(course.id, changes)
    : await api.service('courses').create(course);
  notifyUpdateListeners('courses');
  return newRecord;
}

export async function deleteCourse(original: Course) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('courses').remove(original.id, params);
  notifyUpdateListeners('courses');
}

