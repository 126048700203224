import React, { Component } from 'react';
import TableScreen, { Column } from '../components/TableScreen';
import { FetchUploadGroups } from '../fetchers/FetchUploadGroups';
import { UploadGroup } from '../state/uploadGroupsReducer';


interface Props {
};

interface State {
  page: number;
  search: string;
};

class UploadGroupListScreen extends Component<Props, State> {
  static title = "Upload Groups";
  columns: Column[];

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 0,
      search: '',
    };

    this.columns = [
      { title: "ID" },
      { title: "Title" },
    ];
  }

  onSelectPage = (page: number) => {
    this.setState({ page });
  }

  onSearchChange = (search: string) => {
    this.setState({ search });
  }

  render() {
    return (
      <FetchUploadGroups page={this.state.page} search={this.state.search}>
        {({ uploadGroups, numberOfPages }) =>
          <TableScreen
            title={(this.constructor as any).title}
            singular="Upload Group"
            columns={this.columns}
            list={uploadGroups}
            row={(item: UploadGroup) => [item.id || "", item.title || ""]}
            rowId={(item: UploadGroup) => `${item.id}`}
            detailPath="/uploadgroups/:id"
            currentPage={this.state.page}
            numberOfPages={numberOfPages}
            onSelectPage={this.onSelectPage}
            search={this.state.search}
            onSearchChange={this.onSearchChange}
          />
        }
      </FetchUploadGroups>
    );
  }
}

export default UploadGroupListScreen;
