import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import * as config from '../config';
import rootReducer from './rootReducer';

const restoreState = () => {
  try {
    const jsonState = localStorage.getItem(config.STATE_KEY);
    if (jsonState === null) {
      return undefined;
    }

    return JSON.parse(jsonState);

  } catch (error) {
    console.log(`Error restoring state: ${error}`);
    return undefined;
  }
};

const preserveState = (store: { getState: () => any; }) => {
  try {
    let state = { ...store.getState() };
    const jsonState = JSON.stringify(state);
    localStorage.setItem(config.STATE_KEY, jsonState);

  } catch (error) {
    console.log(`Error preserving state: ${error}`);
  }
};

let middlewares: Middleware[] = [ReduxThunk];
if (config.ENABLE_LOGGING) {
  middlewares.push(createLogger({}));
}

const store = createStore(
  rootReducer,
  restoreState(),
  compose(
    applyMiddleware(...middlewares)
  )
);

store.dispatch({ type: 'RESET_ON_LOAD' });

store.subscribe(() => {
  preserveState(store);
});

export default store;
