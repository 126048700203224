import React, { Component } from 'react';
import TableScreen, { Column, TableFilter } from '../components/TableScreen';
import { FetchCohortMembers } from '../fetchers/FetchCohortMembers';
import UserSelect from '../selects/UserSelect';
import { CohortMember } from '../state/cohortMembersReducer';


interface Props {
};

interface State {
  page: number;
  search: string;
  filterUserId?: number;
  filterCourseId?: number;
};

class CohortMemberListScreen extends Component<Props, State> {
  static title = "Cohort Members";
  columns: Column[];

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 0,
      search: '',
      filterUserId: undefined,
      filterCourseId: undefined,
    };

    this.columns = [
      { title: "Cohort" },
      { title: "User" },
    ];
  }

  onSelectPage = (page: number) => {
    this.setState({ page });
  }

  onSearchChange = (search: string) => {
    this.setState({ search });
  }

  render() {
    return (
      <FetchCohortMembers
        page={this.state.page}
        search={this.state.search}
        query={{
          userId: this.state.filterUserId || undefined,
        }}
      >
        {({ cohortMembers, numberOfPages }) =>
          <TableScreen
            title={(this.constructor as any).title}
            singular="Cohort Member"
            columns={this.columns}
            list={cohortMembers}
            row={(item: CohortMember) => [
              item.cohortId || "",
              (item.user ? `${item.user.firstName || ''} ${item.user.lastName}` : item.userId) || '',
            ]}
            rowId={(item: CohortMember) => `${item.id}`}
            detailPath="/cohortmembers/:id"
            currentPage={this.state.page}
            numberOfPages={numberOfPages}
            onSelectPage={this.onSelectPage}
            search={this.state.search}
            onSearchChange={this.onSearchChange}
            renderFilters={() =>
              <>
                <TableFilter label="User" maxWidth={30}>
                  <UserSelect
                    value={this.state.filterUserId}
                    onChange={filterUserId => this.setState({ filterUserId })}
                  />
                </TableFilter>
              </>
            }
          />
        }
      </FetchCohortMembers>
    );
  }
}

export default CohortMemberListScreen;
