import _ from 'lodash';
import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface Props {
    numberOfPages: number;
    currentPage: number;
    onSelectPage: (pageNumber: number) => void;
}

const Paginator = ({ numberOfPages, currentPage, onSelectPage }: Props) => {
    let firstPage = Math.max(0, currentPage - 4);
    const lastPage = Math.min(Math.ceil(numberOfPages), firstPage + 10);
    if (lastPage - firstPage < 10) {
        firstPage = Math.max(0, lastPage - 10);
    }

    return (
        <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={currentPage === 0}><PaginationLink onClick={() => onSelectPage(currentPage - 1)}>Previous</PaginationLink></PaginationItem>
            {_.range(firstPage, lastPage).map(pageNumber => (
                <PaginationItem active={pageNumber === currentPage} key={pageNumber}><PaginationLink onClick={() => onSelectPage(pageNumber)}>{pageNumber + 1}</PaginationLink></PaginationItem>
            ))}
            <PaginationItem disabled={currentPage >= Math.ceil(numberOfPages) - 1}><PaginationLink onClick={() => onSelectPage(currentPage + 1)}>Next</PaginationLink></PaginationItem>
        </Pagination>
    );
};

export default Paginator;
