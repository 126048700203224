import React, { Component } from 'react';
import TableScreen, { Column, TableFilter } from '../components/TableScreen';
import { FetchCohorts } from '../fetchers/FetchCohorts';
import { Cohort } from '../state/cohortsReducer';
import CourseSelect from '../selects/CourseSelect';


interface Props {
};

interface State {
  page: number;
  search: string;
  filterCourseId?: number;
};

class CohortListScreen extends Component<Props, State> {
  static title = "Cohorts";
  columns: Column[];

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 0,
      search: '',
    };

    this.columns = [
      { title: "ID" },
      { title: "Course" },
      { title: "Access Code" },
    ];
  }

  onSelectPage = (page: number) => {
    this.setState({ page });
  }

  onSearchChange = (search: string) => {
    this.setState({ search });
  }

  render() {
    return (
      <FetchCohorts 
        page={this.state.page} 
        search={this.state.search}
        query={{
          courseId: this.state.filterCourseId || undefined,
        }}
      >
        {({ cohorts, numberOfPages }) =>
          <TableScreen
            title={(this.constructor as any).title}
            singular="Cohort"
            columns={this.columns}
            list={cohorts}
            row={(item: Cohort) => [
              item.id || '',
              item.course?.title || item.courseId || '',
              item.accessCode,
            ]}
            rowId={(item: Cohort) => `${item.id}`}
            detailPath="/cohorts/:id"
            currentPage={this.state.page}
            numberOfPages={numberOfPages}
            onSelectPage={this.onSelectPage}
            search={this.state.search}
            onSearchChange={this.onSearchChange}
            renderFilters={() =>
              <>
                <TableFilter label="Course" maxWidth={30}>
                  <CourseSelect
                    value={this.state.filterCourseId}
                    onChange={filterCourseId => this.setState({ filterCourseId })}
                  />
                </TableFilter>
              </>
            }
          />
        }
      </FetchCohorts>
    );
  }
}

export default CohortListScreen;
