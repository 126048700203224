import { useEffect, useState } from 'react';
import api from '../state/api';
import { CohortFeedInteraction, blankCohortFeedInteraction } from '../state/cohortFeedInteractionsReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchCohortFeedInteractionsResults {
  cohortFeedInteractions?: CohortFeedInteraction[];
  numberOfPages: number;
  numberOfCohortFeedInteractions?: number;
  totalNumberOfCohortFeedInteractions?: number;
};

interface FetchCohortFeedInteractionsProps {
  children: (results: FetchCohortFeedInteractionsResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchCohortFeedInteractions = ({ children, page, search, query }: FetchCohortFeedInteractionsProps): JSX.Element => {
  const [results, setResults] = useState<FetchCohortFeedInteractionsResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('cohort-feed-interactions');

  useEffect(() => {
    let cancelled = false;

    api.service('cohort-feed-interactions').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfCohortFeedInteractions: json.total,
            numberOfPages: json.total / json.limit,
            numberOfCohortFeedInteractions: json.data.length,
            cohortFeedInteractions: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching cohortFeedInteractions: `, error);
        }
      });

    return () => { cancelled = true; };
  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchCohortFeedInteractionProps {
  children: ({ cohortFeedInteraction }: { cohortFeedInteraction: CohortFeedInteraction }) => any;
  id?: number;
};

export const FetchCohortFeedInteraction = ({ children, id }: FetchCohortFeedInteractionProps): JSX.Element => {
  const [cohortFeedInteraction, setCohortFeedInteraction] = useState<CohortFeedInteraction>({ ...blankCohortFeedInteraction });
  const lastRefreshed = useUpdateListener('cohort-feed-interactions');

  useEffect(() => {
    if (!id || !+id) {
      setCohortFeedInteraction(blankCohortFeedInteraction);
      return;
    }

    let cancelled = false;

    api.service('cohort-feed-interactions').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setCohortFeedInteraction(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching cohortFeedInteractions: `, error);
        }
        setCohortFeedInteraction({ ...blankCohortFeedInteraction });
      });

    return () => { cancelled = true; };
  }, [id, lastRefreshed]);

  return children({ cohortFeedInteraction });
};

