import moment from 'moment';
import React, { Component, FormEvent } from 'react';
import { Input } from 'reactstrap';

import { DATE_TIME_FORMAT } from '../config';

interface Props {
  name?: string;
  id?: string;
  value?: string | number;
  onChange?: (newValue: number | undefined) => void;
  [name: string]: any;
  readOnly?: boolean;
}

interface State {
  stringValue: string;
  lastValue?: number | string;
}

class DateTimeInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stringValue: props.value ? moment(props.value).format(DATE_TIME_FORMAT) : '',
      lastValue: props.value,
    };
  }

  componentDidUpdate(newProps: Props, newState: State) {
    if (newProps.value !== newState.lastValue) {
      this.setState({
        stringValue: newProps.value ? moment(newProps.value).format(DATE_TIME_FORMAT) : '',
        lastValue: newProps.value,
      });
    }
  }

  onChange = (event: FormEvent<any>) => {
    const m = moment(event.currentTarget.value, DATE_TIME_FORMAT);
    let { lastValue } = this.state;

    if (m) {
      lastValue = +m;
    }

    this.setState({
      stringValue: event.currentTarget.value,
      lastValue
    });

    if (m) {
      this.props.onChange && this.props.onChange(+m);
    }
  }

  onBlur = (_event: FormEvent<any>) => {
    const { lastValue } = this.state;
    this.setState({
      stringValue: lastValue ? moment(lastValue).format(DATE_TIME_FORMAT) : '',
    });
  }

  render() {
    const { name, id, value, onChange, readOnly, ...props } = this.props;
    const { stringValue } = this.state;
    return (
      <>
        <Input
          type="text"
          name={name}
          id={id}
          value={stringValue}
          onChange={this.onChange}
          onBlur={this.onBlur}
          readOnly={readOnly}
          {...props}
        />
        <div>
          {/* {`value: ${value ? moment(value).format(DATE_TIME_FORMAT) : 'undefined'}`}<br/> */}
          {/* {`lastValue: ${this.state.lastValue ? moment(this.state.lastValue).format(DATE_TIME_FORMAT) : 'undefined'}`}<br/> */}
        </div>
      </>
    );
  }
}

export default DateTimeInput;
