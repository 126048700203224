import React from 'react';

/// Puts a screen inside another component, e.g., a RequiredLogin.
export default function wrapScreen(Screen: any, Wrapper: any, wrapperProps = {}) {
  const WrappedComponent = (props: any) => (
    <Wrapper {...props} {...wrapperProps}>
      <Screen {...props} />
    </Wrapper>
  );

  WrappedComponent.title = Screen.title;

  return WrappedComponent;
}
