import { LOGGED_OUT } from './loginReducer';

export enum HabitRepeat {
  EveryDay = "everyday",
  EveryWeek = "everyweek",
  TwiceWeek = "twiceweek",
  FiveTimesWeek = "fivetimesweek"
};

/// Anything with a ? was added in a later API version
export interface Habit {
  id: number;
  userId: number;
  title: string;
  question: string | null;
  color: string | null;
  repeat: HabitRepeat;
  reminderTime: Date | null;
  monthlyScore: number;
  checkedDays: object | null;
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
};

export const blankHabit: Habit = {
  id: 0,
  userId: 0,
  title: '',
  question: null,
  color: null,
  repeat: HabitRepeat.EveryDay,
  reminderTime: null,
  monthlyScore: 0,
  checkedDays: null,
  isArchived: false,
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type HabitsStore = typeof initialState;

const habits = (state = initialState, action: any): HabitsStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default habits;
