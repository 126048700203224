import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import PageTitle from './components/PageTitle';
import RequireLogin from './components/RequireLogin';
import Sidebar, { Screen } from './components/Sidebar';
import wrapScreen from './components/wrapScreen';
import AppSettingDetailScreen from './screens/AppSettingDetailScreen';
import AppSettingListScreen from './screens/AppSettingListScreen';
import CohortDetailScreen from './screens/CohortDetailScreen';
import CohortFeedInteractionDetailScreen from './screens/CohortFeedInteractionDetailScreen';
import CohortFeedInteractionListScreen from './screens/CohortFeedInteractionListScreen';
import CohortFeedItemDetailScreen from './screens/CohortFeedItemDetailScreen';
import CohortFeedItemListScreen from './screens/CohortFeedItemListScreen';
import CohortListScreen from './screens/CohortListScreen';
import CohortMemberDetailScreen from './screens/CohortMemberDetailScreen';
import CohortMemberListScreen from './screens/CohortMemberListScreen';
import CourseDetailScreen from './screens/CourseDetailScreen';
import CourseListScreen from './screens/CourseListScreen';
import DashboardScreen from './screens/DashboardScreen';
import HabitDetailScreen from './screens/HabitDetailScreen';
import HabitListScreen from './screens/HabitListScreen';
import HelpScreen from './screens/HelpScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import UploadDetailScreen from './screens/UploadDetailScreen';
import UploadGroupDetailScreen from './screens/UploadGroupDetailScreen';
import UploadGroupListScreen from './screens/UploadGroupListScreen';
import UploadListScreen from './screens/UploadListScreen';
import UserDetailScreen from './screens/UserDetailScreen';
import UserListScreen from './screens/UserListScreen';
import VerifyTokenScreen from './screens/VerifyTokenScreen';
import { postLogout } from './state/loginActions';
import { CombinedStore } from './state/rootReducer';
import { IS_PRODUCTION } from './config';

const SCREENS: Screen[] = [
  { path: "/", component: wrapScreen(DashboardScreen, RequireLogin) },
  { isAdmin: true, path: "/users/:userid", component: wrapScreen(UserDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/users", component: wrapScreen(UserListScreen, RequireLogin) },
  { isAdmin: true, path: "/courses/:courseid", component: wrapScreen(CourseDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/courses", component: wrapScreen(CourseListScreen, RequireLogin) },
  { isAdmin: true, path: "/cohorts/:cohortid", component: wrapScreen(CohortDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/cohorts", component: wrapScreen(CohortListScreen, RequireLogin) },
  { isAdmin: true, path: "/cohortmembers/:cohortmemberid", component: wrapScreen(CohortMemberDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/cohortmembers", component: wrapScreen(CohortMemberListScreen, RequireLogin) },
  { isAdmin: true, path: "/cohortfeeditems/:cohortfeeditemid", component: wrapScreen(CohortFeedItemDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/cohortfeeditems", component: wrapScreen(CohortFeedItemListScreen, RequireLogin) },
  { isAdmin: true, path: "/cohortfeedinteractions/:cohortfeedinteractionid", component: wrapScreen(CohortFeedInteractionDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/cohortfeedinteractions", component: wrapScreen(CohortFeedInteractionListScreen, RequireLogin) },
  { isAdmin: true, path: "/habits/:habitid", component: wrapScreen(HabitDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/habits", component: wrapScreen(HabitListScreen, RequireLogin) },
  { isAdmin: true, path: "/uploads/:uploadid", component: wrapScreen(UploadDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/uploads", component: wrapScreen(UploadListScreen, RequireLogin) },
  { isAdmin: true, path: "/uploadgroups/:uploadgroupid", component: wrapScreen(UploadGroupDetailScreen, RequireLogin) },
  { isAdmin: true, path: "/uploadgroups", component: wrapScreen(UploadGroupListScreen, RequireLogin) },
  { bottom: true, isAdmin: true, path: "/help", component: wrapScreen(HelpScreen, RequireLogin) },
];

if (!IS_PRODUCTION) {
  SCREENS.push({ isAdmin: true, path: "/appsettings/:appsettingid", component: wrapScreen(AppSettingDetailScreen, RequireLogin) });
  SCREENS.push({ isAdmin: true, path: "/appsettings", component: wrapScreen(AppSettingListScreen, RequireLogin) });
}

interface Props {

}

interface StateProps {
  isLoggedIn: boolean;
  role: string;
}

interface DispatchProps {
  postLogOut: () => void;
}

interface ParamProps {
}

type CombinedProps = RouteComponentProps<ParamProps> & Props & StateProps & DispatchProps;

const NotPermitted = (): JSX.Element =>
  <RequireLogin>
    <PageTitle title="Not Permitted" />
    You do not have permission to access this page
  </RequireLogin>;

class App extends Component<CombinedProps> {
  onLogOutPressed = () => {
    this.props.postLogOut();
  }

  render() {
    const { role, isLoggedIn } = this.props;

    return (
      <div className="App">
        {isLoggedIn && <Sidebar titleLink="/" screens={SCREENS} onLogOutPressed={this.onLogOutPressed} isUserAnAdmin={role !== ''} />}
        <main className={isLoggedIn ? '' : 'not-logged-in'}>
          <Container fluid>
            {/* <NotVerifiedAlert /> */}
            <Switch>
              <Route path="/verify/:token" component={VerifyTokenScreen} />
              <Route path="/login" component={LoginScreen} />
              <Route path="/register" component={RegisterScreen} />
              <Route path="/resetpassword/:token" component={ResetPasswordScreen} />
              <Route path="/resetpassword" component={ResetPasswordScreen} />
              {SCREENS.map(screen =>
                !screen.isAdmin || role !== ''
                  ? <Route exact key={screen.path} path={screen.path} component={screen.component} />
                  : <Route exact key={screen.path} path={screen.path} component={NotPermitted} />
              )}
            </Switch>
          </Container>
        </main>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: CombinedStore): StateProps => ({
      isLoggedIn: state.login.isLoggedIn,
      role: state.login.user?.role || '',
    }),
    (dispatch: (action: any) => void): DispatchProps => ({
      postLogOut: () => dispatch(postLogout()),
    })
  )(App));
