import { useEffect, useState } from 'react';
import api from '../state/api';
import { Course, blankCourse } from '../state/coursesReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchCoursesResults {
  courses?: Course[];
  numberOfPages: number;
  numberOfCourses?: number;
  totalNumberOfCourses?: number;
};

interface FetchCoursesProps {
  children: (results: FetchCoursesResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchCourses = ({ children, page, search, query }: FetchCoursesProps): JSX.Element => {
  const [results, setResults] = useState<FetchCoursesResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('courses');

  useEffect(() => {
    let cancelled = false;

    api.service('courses').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfCourses: json.total,
            numberOfPages: json.total / json.limit,
            numberOfCourses: json.data.length,
            courses: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching courses: `, error);
        }
      });

      return () => { cancelled = true; };
    }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchCourseProps {
  children: ({ course }: { course: Course }) => any;
  id?: number;
};

export const FetchCourse = ({ children, id }: FetchCourseProps): JSX.Element => {
  const [course, setCourse] = useState<Course>({ ...blankCourse });
  const lastRefreshed = useUpdateListener('courses');

  useEffect(() => {
    if (!id || !+id) {
      setCourse(blankCourse);
      return;
    }

    let cancelled = false;

    api.service('courses').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setCourse(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching courses: `, error);
        }
        setCourse({ ...blankCourse });
      });

      return () => { cancelled = true; };
    }, [id, lastRefreshed]);

  return children({ course });
};

