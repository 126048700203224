import './PageTitle.scss';

import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

export interface RouteItem {
  [visibleName: string]: string; // visibleName to URI
}

interface Props {
  title: string;
  children?: any;
  route?: RouteItem[];
  renderPageLinks?: () => any;
}

const PageTitle = ({ title, children, route, renderPageLinks }: Props) =>
  <div className="PageTitle-container mt-3 mb-3">
    <div className="PageTitle-accesories">
      {children ? children : null}
    </div>
    <div className="PageTitle-title">
      {route
        ? <Breadcrumb>
          {route.map(item => Object.keys(item).map(key => <BreadcrumbItem key={key}><RouterNavLink to={item[key]}>{key}</RouterNavLink></BreadcrumbItem>))}
          <BreadcrumbItem>{title}</BreadcrumbItem>
        </Breadcrumb>
        : <h4>{title}</h4>
      }
      {renderPageLinks && renderPageLinks()}
    </div>
  </div>;

export default PageTitle;
