import React, { Component } from 'react';
import TableScreen, { Column } from '../components/TableScreen';
import { FetchUsers } from '../fetchers/FetchUsers';
import { User } from '../state/usersReducer';


interface Props {
};

interface State {
  page: number;
  search: string;
};

class UserListScreen extends Component<Props, State> {
  static title = "Users";
  columns: Column[];

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 0,
      search: '',
    };

    this.columns = [
      { title: "ID" },
      { title: "Email" },
      { title: "Role" },
      { title: "First Name" },
      { title: "Last Name" },
    ];
  }

  onSelectPage = (page: number) => {
    this.setState({ page });
  }

  onSearchChange = (search: string) => {
    this.setState({ search });
  }

  render() {
    return (
      <FetchUsers page={this.state.page} search={this.state.search}>
        {({ users, numberOfPages }) =>
          <TableScreen
            title={(this.constructor as any).title}
            singular="User"
            columns={this.columns}
            list={users}
            row={(item: User) => [item.id || '', item.email || '', item.role || '', item.firstName || '', item.lastName || '']}
            rowId={(item: User) => `${item.id}`}
            detailPath="/users/:id"
            currentPage={this.state.page}
            numberOfPages={numberOfPages}
            onSelectPage={this.onSelectPage}
            search={this.state.search}
            onSearchChange={this.onSearchChange}
          />
        }
      </FetchUsers>
    );
  }
}

export default UserListScreen;
