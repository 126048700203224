import { combineReducers } from 'redux';
import appSettings from './appSettingsReducer';
import cohortFeedInteractions from './cohortFeedInteractionsReducer';
import cohortFeedItems from './cohortFeedItemsReducer';
import cohortMembers from './cohortMembersReducer';
import cohorts from './cohortsReducer';
import courses from './coursesReducer';
import habits from './habitsReducer';
import login from './loginReducer';
import uploadGroups from './uploadGroupsReducer';
import uploads from './uploadsReducer';
import users from './usersReducer';

const reducers = { 
  login,
  cohorts,
  users,
  courses,
  cohortMembers,
  cohortFeedItems,
  cohortFeedInteractions,
  appSettings,
  habits,
  uploads,
  uploadGroups,
};

const rootReducer = combineReducers(reducers);

type ReducersType = typeof reducers;

export type CombinedStore = {
  [P in keyof ReducersType]: ReturnType<ReducersType[P]>;
};

export default rootReducer;
