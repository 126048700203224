import { useEffect, useState } from 'react';

interface ListenersForRecordType {
  byHandle: { [handle: string]: (...params: any[]) => void };
  next: number;
}

const listenersByRecordType: { [recordType: string]: ListenersForRecordType } = {};

export const addUpdateListener = (recordType: string, onUpdateRequired: () => void): any => {
  if (!listenersByRecordType[recordType]) {
    listenersByRecordType[recordType] = { byHandle: {}, next: 0 };
  }

  const listeners = listenersByRecordType[recordType];

  const handle = String(listenersByRecordType[recordType].next++);

  listeners.byHandle[handle] = onUpdateRequired;

  return { handle, recordType };
};

export const removeUpdateListener = (handle: any) => {
  if (!listenersByRecordType[handle.recordType]) {
    return;
  }

  const listeners = listenersByRecordType[handle.recordType];

  if (listeners.byHandle[handle.handle]) {
    delete listeners.byHandle[handle.handle];
  }
};

export const notifyUpdateListeners = (recordType: string, ...params: any[]) => {
  const listeners = listenersByRecordType[recordType];
  if (!listeners) {
    return;
  }

  Object.keys(listeners.byHandle).forEach(handle => {
    const value = listeners.byHandle[handle];
    // Allow handler sto be removed during each callback by checking the handle still exists
    if (value) {
      value.apply(undefined, params);
    }
  });
};

export const useUpdateListener = (recordType: string): any => {
  const [lastRefreshed, setLastRefreshed] = useState(Date.now());

  useEffect(() => {
    const handle = addUpdateListener(recordType, () => {
      setLastRefreshed(Date.now());
    });

    return () => removeUpdateListener(handle);
  }, [recordType]);

  return lastRefreshed;
};
