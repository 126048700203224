import api from './api';
import { blankUser, User } from './usersReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveUser(original: User, changes: Partial<User>): Promise<User> {
  const user: User = { ...blankUser, ...original, ...changes };

  const params = {
    query: {
      $admin: true,
    },
  };

  const newRecord = user.id
    ? await api.service('users').patch(user.id, changes, params)
    : await api.service('users').create(user, params);
  notifyUpdateListeners('users');
  return newRecord;
}

export async function deleteUser(original: User) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('users').remove(original.id, params);
  notifyUpdateListeners('users');
}
