import api from './api';
import { blankCohortFeedInteraction, CohortFeedInteraction } from './cohortFeedInteractionsReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveCohortFeedInteraction(original: CohortFeedInteraction, changes: Partial<CohortFeedInteraction>): Promise<CohortFeedInteraction> {
  const cohortFeedInteraction: CohortFeedInteraction = { ...blankCohortFeedInteraction, ...original, ...changes };

  const params = {
    query: {
      $admin: true,
    },
  };

  const newRecord = cohortFeedInteraction.id
    ? await api.service('cohort-feed-interactions').patch(cohortFeedInteraction.id, changes, params)
    : await api.service('cohort-feed-interactions').create(cohortFeedInteraction, params);
  notifyUpdateListeners('cohort-feed-interactions');
  return newRecord;
}

export async function deleteCohortFeedInteraction(original: CohortFeedInteraction) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('cohort-feed-interactions').remove(original.id, params);
  notifyUpdateListeners('cohort-feed-interactions');
}

