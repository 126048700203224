import { useEffect, useState } from 'react';
import api from '../state/api';
import { blankUpload, Upload } from '../state/uploadsReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchUploadsResults {
  uploads?: Upload[];
  numberOfPages: number;
  numberOfUploads?: number;
  totalNumberOfUploads?: number;
};

interface FetchUploadsProps {
  children: (results: FetchUploadsResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchUploads = ({ children, page, search, query }: FetchUploadsProps): JSX.Element => {
  const [results, setResults] = useState<FetchUploadsResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('uploads');

  useEffect(() => {
    let cancelled = false;

    api.service('uploads').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfUploads: json.total,
            numberOfPages: json.total / json.limit,
            numberOfUploads: json.data.length,
            uploads: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching uploads: `, error);
        }
      });

    return () => { cancelled = true; };
  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchUploadProps {
  children: ({ upload }: { upload: Upload }) => any;
  id?: number;
};

export const FetchUpload = ({ children, id }: FetchUploadProps): JSX.Element => {
  const [upload, setUpload] = useState<Upload>({ ...blankUpload });
  const lastRefreshed = useUpdateListener('uploads');

  useEffect(() => {
    if (!id || !+id) {
      setUpload(blankUpload);
      return;
    }

    let cancelled = false;

    api.service('uploads').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setUpload(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching uploads: `, error);
        }
        setUpload({ ...blankUpload });
      });

    return () => { cancelled = true; };
  }, [id, lastRefreshed]);

  return children({ upload });
};

