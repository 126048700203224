import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface Cohort {
  id: number;
  courseId: number;
  accessCode: string;
  createdAt: string;
  updatedAt: string;
  course?: {
    title: string;
  }
};

export const blankCohort: Cohort = {
  id: 0,
  courseId: 0,
  accessCode: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type CohortsStore = typeof initialState;

const cohorts = (state = initialState, action: any): CohortsStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default cohorts;
