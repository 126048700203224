import api from './api';
import { AppSetting, blankAppSetting } from './appSettingsReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveAppSetting(original: AppSetting, changes: Partial<AppSetting>): Promise<AppSetting> {
  const appSetting: AppSetting = { ...blankAppSetting, ...original, ...changes };

  const newRecord = appSetting.id
    ? await api.service('app-settings').patch(appSetting.id, changes)
    : await api.service('app-settings').create(appSetting);
  notifyUpdateListeners('app-settings');
  return newRecord;
}

export async function deleteAppSetting(original: AppSetting) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('app-settings').remove(original.id, params);
  notifyUpdateListeners('app-settings');
}

