import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Input, Row, Table } from 'reactstrap';
import PageTitle from '../components/PageTitle';
import Paginator from '../components/Paginator';
import Spinner from '../components/Spinner';
import { CombinedStore } from '../state/rootReducer';
import { closest } from '../utils';

interface StateProps {
  isLoggedIn: boolean;
};

interface DispatchProps {
};

export interface Column {
  title: string;
};

interface Props {
  onClick?: (id: string) => void;
  detailPath?: string;
  title: string;
  renderFilters?: () => any;
  columns: Column[];
  list?: any[];
  rowId?: (item: any) => string;
  singular: string;
  row: (item: any, index: number) => any[];
  currentPage?: number;
  numberOfPages?: number;
  onSelectPage?: (pageNumber: number) => void;
  search?: string;
  onSearchChange?: (newSearch: string) => void;
};

type CombinedProps = Props & StateProps & DispatchProps & RouteComponentProps<{}>;

class TableScreen extends Component<CombinedProps> {
  renderFilters = () => {
    return this.props.renderFilters && this.props.renderFilters();
  }

  renderNoRecords = () => {
    return (
      <tr>
        <td colSpan={this.props.columns.length}>No records</td>
      </tr>
    );
  }

  renderSpinner = () => {
    return (
      <tr>
        <td colSpan={this.props.columns.length}>
          <Spinner text="Fetching records..." />
        </td>
      </tr>
    );
  }

  tableClicked = (event: React.FormEvent<any>) => {
    const tr = closest(event.target as Node, 'tr');
    if (tr && tr instanceof HTMLTableRowElement) {
      const id = tr.dataset.id || '';
      if (this.props.onClick) {
        this.props.onClick(id);
      } else if (this.props.detailPath) {
        this.props.history.push(this.props.detailPath.replace(/:id/g, encodeURIComponent(id)));
      }
    }
  }

  newRecord = (event: React.FormEvent<any>) => {
    event.preventDefault();
    if (this.props.detailPath) {
      this.props.history.push(this.props.detailPath.replace(/:id/g, encodeURIComponent('new')));
    }
  }

  onSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.props.onSearchChange && this.props.onSearchChange(event.currentTarget.value);
    this.props.onSelectPage && this.props.onSelectPage(0);
  }

  render() {
    return (
      <>
        <PageTitle title={this.props.title}>
          <Button className="float-right" color="primary" onClick={this.newRecord}>Create {this.props.singular}</Button>
        </PageTitle>
        <Row>
          <Col xs={12}>
            {this.props.list
              ? <Card>
                {this.props.onSearchChange && this.props.search !== undefined
                  ? (
                    <CardHeader>
                      <div className="d-flex d-flex-row justify-content-between">
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                          {this.renderFilters()}
                        </div>
                        <div style={{ flex: 1, maxWidth: '20rem', display: 'inline-block' }}>
                          <Input type="text" placeholder="Search" value={this.props.search || ''} onChange={this.onSearchChange} />
                        </div>
                      </div>
                    </CardHeader>
                  ) : this.props.renderFilters
                    ? (
                      <CardHeader>
                        {this.renderFilters()}
                      </CardHeader>
                    ) : null
                }
                <CardBody>
                  <Table responsive hover onClick={this.tableClicked} className="mb-0">
                    <thead>
                      <tr>
                        {this.props.columns.map((column, index) => <th key={index}>{column.title}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.list
                        ? (this.props.list.length
                          ? this.props.list.map((item, index) =>
                            <tr key={index} data-id={this.props.rowId ? this.props.rowId(item) : ''}>
                              {this.props.row(item, index).map((string, index) => <td key={index}>{string}</td>)}
                            </tr>)
                          : <this.renderNoRecords />
                        )
                        : <this.renderSpinner />
                      }
                    </tbody>
                  </Table>
                </CardBody>
                {this.props.numberOfPages !== undefined && this.props.currentPage !== undefined && this.props.onSelectPage !== undefined &&
                  <CardFooter>
                    <Paginator numberOfPages={this.props.numberOfPages} currentPage={this.props.currentPage} onSelectPage={this.props.onSelectPage} />
                  </CardFooter>
                }
              </Card>
              : <div />
            }
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: CombinedStore): StateProps => ({
  isLoggedIn: state.login.isLoggedIn,
});

const mapDispatchToProps = (_dispatch: (action: any) => void): DispatchProps => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableScreen));

export const TableFilter = ({ children, maxWidth, label }: { children: any; maxWidth?: number; label?: string; }): JSX.Element =>
  <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'baseline', justifyContent: 'baseline', marginRight: '1rem', maxWidth: `${maxWidth}rem` }}>
    {label && <label style={{ marginRight: '1rem' }}>{label}</label>}
    {children}
  </div>;
