import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { Button, Card, CardFooter, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FormErrorText from '../components/FormErrorText';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { LogoCardBody, LogoCardHeader } from '../components/LogoCardHeader';
import { postPasswordReset, postPasswordResetRequest } from '../state/loginActions';
import { CombinedStore } from '../state/rootReducer';

interface StateProps {
  isLoggedIn: boolean,
  passwordResetRequestState: string,
  passwordResetState: string,
};

interface DispatchProps {
  postPasswordResetRequest: (email: string) => void;
  postPasswordReset: (token: string, password: string) => void;
};

interface Props {
};

interface Params {
  token?: string;
}

type CombinedProps = Props & StateProps & DispatchProps & RouteComponentProps<Params>;

interface State {
  email: string;
  password: string;
  password2: string;
  posted: boolean;
  errors: any;
};

class ResetPasswordScreen extends Component<CombinedProps, State> {
  errors: { [id: string]: string } = {};

  constructor(props: CombinedProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      password2: '',
      posted: false,
      errors: {},
    };
  }

  onEmailChanged = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ email: event.currentTarget.value });
  }

  onPasswordChanged = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password: event.currentTarget.value });
  }

  onPassword2Changed = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password2: event.currentTarget.value });
  }

  onRequestPasswordResetPressed = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const errors: any = {};

    const { email } = this.state;

    if (email.indexOf('@') < 1) {
      errors['email'] = "Please enter your email address";
    }

    this.setState({ errors, posted: true });
    if (_.keys(errors).length > 0) {
      return;
    }

    this.props.postPasswordResetRequest(this.state.email);
  }

  onResetPasswordPressed = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const errors: any = {};

    const { password, password2 } = this.state;
    if (password.length < 8) {
      errors['password'] = "Password must be at least 8 characters";
    } else if (password2 !== password) {
      errors['password2'] = "Passwords do not match";
    }

    this.setState({ errors, posted: true });
    if (_.keys(errors).length > 0) {
      return;
    }

    this.props.postPasswordReset(this.props.match.params.token || '', password);
  }

  onTryAgainClicked = (event: React.FormEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ posted: false });
  };

  render() {
    return (
      <Container className="mt-5 mb-5 ResetPasswordScreen">
        <Row className="justify-content-center">
          <Col xs="auto">
            <Card className="position-relative">
              <LogoCardHeader />
              <LogoCardBody className="p-4">
                {(this.props.match.params.token || '') === ''
                  ? (
                    this.state.posted && this.props.passwordResetRequestState === 'failed'
                      ? (
                        <>
                          <p className="text-center">We've been unable to process your request. No further details are available.
                            You may <a href="/" onClick={this.onTryAgainClicked}>try again</a>.</p>
                        </>
                      ) : (
                        this.state.posted && this.props.passwordResetRequestState === 'complete'
                          ? (
                            <>
                              <p className="text-center">
                                Your request has been submitted. Please check your email for further instructions.
                              </p>
                            </>
                          ) : (
                            <>
                              <h4 className="text-center">Enter your email address and we'll send you an email with instructions on how to reset your password</h4>
                              <Form>
                                <FormGroup>
                                  <Label for="email">Email</Label>
                                  <Input type="email" name="email" onChange={this.onEmailChanged} value={this.state.email} />
                                  <FormErrorText error={this.state.posted && this.state.errors.email} />
                                </FormGroup>
                              </Form>
                              <div className="text-center mt-4">
                                <Button color="primary" onClick={this.onRequestPasswordResetPressed}>Request Password Reset</Button>
                              </div>
                            </>
                          )
                      )
                  ) : (
                    this.state.posted && this.props.passwordResetState === 'failed'
                      ? (
                        <>
                          <p>We've been unable to reset your password. No further details are available.
                            You may <a href="/" onClick={this.onTryAgainClicked}>try again</a>.
                          </p>
                        </>
                      ) : this.state.posted && this.props.passwordResetState === 'complete'
                        ? (
                          <>
                            <p>Your password has been reset. Please <Link to="/login">sign in</Link>.</p>
                          </>
                        ) : (
                          <>
                            <h4 className="text-center">Enter a new password</h4>
                            <Form>
                              <FormGroup>
                                <Label for="password">Password</Label>
                                <Input type="password" name="password" onChange={this.onPasswordChanged} value={this.state.password} />
                                <FormErrorText error={this.state.posted && this.state.errors.password} />
                              </FormGroup>
                              <FormGroup>
                                <Label for="password2">Password again, to confirm</Label>
                                <Input type="password" name="password2" onChange={this.onPassword2Changed} value={this.state.password2} />
                                <FormErrorText error={this.state.posted && this.state.errors.password2} />
                              </FormGroup>
                            </Form>
                            <div className="text-center mt-4">
                              <Button color="primary" onClick={this.onResetPasswordPressed}>Reset Password</Button>
                            </div>
                          </>
                        )
                  )}
              </LogoCardBody>
              {(this.props.match.params.token || '') === '' &&
                <CardFooter className="text-center">
                  Remembered your password? <Link to="/login">Sign in here</Link>
                </CardFooter>
              }
              {(this.props.passwordResetRequestState === 'in_progress' || this.props.passwordResetState === 'in_progress') && <LoadingOverlay />}
            </Card>
          </Col>
        </Row >
      </Container >
    );
  }
}

const mapStateToProps = (state: CombinedStore): StateProps => ({
  isLoggedIn: state.login.isLoggedIn,
  passwordResetRequestState: state.login.passwordResetRequestState,
  passwordResetState: state.login.passwordResetState,
});

const mapDispatchToProps = (dispatch: (action: any) => void): DispatchProps => ({
  postPasswordResetRequest: (email) => dispatch(postPasswordResetRequest(email)),
  postPasswordReset: (token, password) => dispatch(postPasswordReset(token, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordScreen));
