import { useEffect, useState } from 'react';
import api from '../state/api';
import { blankUser, User } from '../state/usersReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchUsersResults {
  users?: User[];
  numberOfPages: number;
  numberOfUsers?: number;
  totalNumberOfUsers?: number;
};

interface FetchUsersProps {
  children: (results: FetchUsersResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchUsers = ({ children, page, search, query }: FetchUsersProps): JSX.Element => {
  const [results, setResults] = useState<FetchUsersResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('users');

  useEffect(() => {
    let cancelled = false;

    api.service('users').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfUsers: json.total,
            numberOfPages: json.total / json.limit,
            numberOfUsers: json.data.length,
            users: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching users: `, error);
        }
      });

    return () => { cancelled = true; };
  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchUserProps {
  children: ({ user }: { user: User }) => any;
  id?: number;
};

export const FetchUser = ({ children, id }: FetchUserProps): JSX.Element => {
  const [user, setUser] = useState<User>({ ...blankUser });
  const lastRefreshed = useUpdateListener('users');

  useEffect(() => {
    if (!id || !+id) {
      setUser(blankUser);
      return;
    }

    let cancelled = false;

    api.service('users').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setUser(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching users: `, error);
        }
        setUser({ ...blankUser });
      });

    return () => { cancelled = true; };
  }, [id, lastRefreshed]);

  return children({ user });
};

