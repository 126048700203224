import { useEffect, useState } from 'react';
import api from '../state/api';
import { CohortFeedItem, blankCohortFeedItem } from '../state/cohortFeedItemsReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchCohortFeedItemsResults {
  cohortFeedItems?: CohortFeedItem[];
  numberOfPages: number;
  numberOfCohortFeedItems?: number;
  totalNumberOfCohortFeedItems?: number;
};

interface FetchCohortFeedItemsProps {
  children: (results: FetchCohortFeedItemsResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchCohortFeedItems = ({ children, page, search, query }: FetchCohortFeedItemsProps): JSX.Element => {
  const [results, setResults] = useState<FetchCohortFeedItemsResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('cohort-feed-items');

  useEffect(() => {
    let cancelled = false;

    api.service('cohort-feed-items').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfCohortFeedItems: json.total,
            numberOfPages: json.total / json.limit,
            numberOfCohortFeedItems: json.data.length,
            cohortFeedItems: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching cohortFeedItems: `, error);
        }
      });

    return () => { cancelled = true; };
  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchCohortFeedItemProps {
  children: ({ cohortFeedItem }: { cohortFeedItem: CohortFeedItem }) => any;
  id?: number;
};

export const FetchCohortFeedItem = ({ children, id }: FetchCohortFeedItemProps): JSX.Element => {
  const [cohortFeedItem, setCohortFeedItem] = useState<CohortFeedItem>({ ...blankCohortFeedItem });
  const lastRefreshed = useUpdateListener('cohort-feed-items');

  useEffect(() => {
    if (!id || !+id) {
      setCohortFeedItem(blankCohortFeedItem);
      return;
    }

    let cancelled = false;

    api.service('cohort-feed-items').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setCohortFeedItem(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching cohortFeedItems: `, error);
        }
        setCohortFeedItem({ ...blankCohortFeedItem });
      });

    return () => { cancelled = true; };
  }, [id, lastRefreshed]);

  return children({ cohortFeedItem });
};

