import React from 'react';
import { SelectOption, StyledAsyncSelect } from '../components/StyledSelect';
import { FetchCourse } from '../fetchers/FetchCourses';
import api from '../state/api';

interface Props {
  id?: string;
  value?: number;
  onChange: (newValue: number | number) => void;
}

const format = (item: any): string => `${item.id} ${item.title}`;

const CourseSelect = ({ value, onChange, ...props }: Props): JSX.Element => {
  const valueString = String(value);

  return (
    <FetchCourse id={value}>
      {({ course }) =>
        <StyledAsyncSelect<SelectOption>
          {...props}
          cacheOptions
          defaultOptions
          loadOptions={async (search: string): Promise<SelectOption[]> => {
            const query = search.trim().length > 0 ? { $search: search } : {};
            const results = await api.service('courses').find({
              query: {
                ...query,
                $admin: true,
              }
            });
            return results.data.map((item: any) => ({
              value: String(item.id),
              label: format(item),
            }));
          }}
          value={{ value: valueString, label: course.id ? format(course) : "Please select" }}
          onChange={(newValue: any) => onChange(newValue ? newValue.value : '')}
          styles={{
            container: base => ({ ...base, flex: 1 }),
          }}
          isClearable
        />
      }
    </FetchCourse>
  );
};

export default CourseSelect;
