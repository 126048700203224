import api from './api';
import { blankCohortFeedItem, CohortFeedItem } from './cohortFeedItemsReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveCohortFeedItem(original: CohortFeedItem, changes: Partial<CohortFeedItem>): Promise<CohortFeedItem> {
  const cohortFeedItem: CohortFeedItem = { ...blankCohortFeedItem, ...original, ...changes };

  const params = {
    query: {
      $admin: true,
    },
  };

  const newRecord = cohortFeedItem.id
    ? await api.service('cohort-feed-items').patch(cohortFeedItem.id, changes, params)
    : await api.service('cohort-feed-items').create(cohortFeedItem, params);
  notifyUpdateListeners('cohort-feed-items');
  return newRecord;
}

export async function deleteCohortFeedItem(original: CohortFeedItem) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('cohort-feed-items').remove(original.id, params);
  notifyUpdateListeners('cohort-feed-items');
}

