import React from 'react';
import { FormText } from 'reactstrap';

interface Props {
  error: any;
}

const FormErrorText = ({ error }: Props) => 
  error ? (
    <FormText color="danger">{error}</FormText>
  ) : null;

export default FormErrorText;
