import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { FetchCohortMember } from '../fetchers/FetchCohortMembers';
import CohortSelect from '../selects/CohortSelect';
import UserSelect from '../selects/UserSelect';
import { CohortMember } from '../state/cohortMembersReducer';
import { deleteCohortMember, saveCohortMember } from '../state/cohortMembersActions';

interface ParamProps {
  cohortmemberid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class CohortMemberDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField, changeFieldValue }: FormCallbacks<CohortMember>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="cohortId">Cohort</Label>
              <CohortSelect
                id="cohortId"
                value={getField('cohortId')}
                onChange={newValue => changeFieldValue('cohortId', null, newValue)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="userId">User</Label>
              <UserSelect
                id="userId"
                value={getField('userId')}
                onChange={newValue => changeFieldValue('userId', null, newValue)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<CohortMember>, original: CohortMember, changes: Partial<CohortMember>): Promise<CohortMember> => {
    return await saveCohortMember(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<CohortMember>, original: CohortMember): Promise<void> => {
    return await deleteCohortMember(original);
  }

  render() {
    return (
      <FetchCohortMember id={+this.props.match.params.cohortmemberid}>
        {({ cohortMember }) =>
          <DetailScreen<CohortMember>
            original={cohortMember}
            isNew={this.props.match.params.cohortmemberid === 'new'}
            titleField="id"
            titleIfNew="New Cohort Member"
            route={[{ 'Cohort Members': '/cohortmembers' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchCohortMember>
    );
  }
}

export default CohortMemberDetailScreen;
