import { useEffect, useState } from 'react';
import api from '../state/api';
import { Habit, blankHabit } from '../state/habitsReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchHabitsResults {
  habits?: Habit[];
  numberOfPages: number;
  numberOfHabits?: number;
  totalNumberOfHabits?: number;
};

interface FetchHabitsProps {
  children: (results: FetchHabitsResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchHabits = ({ children, page, search, query }: FetchHabitsProps): JSX.Element => {
  const [results, setResults] = useState<FetchHabitsResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('habits');

  useEffect(() => {
    let cancelled = false;

    api.service('habits').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfHabits: json.total,
            numberOfPages: json.total / json.limit,
            numberOfHabits: json.data.length,
            habits: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching habits: `, error);
        }
      })

  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchHabitProps {
  children: ({ habit }: { habit: Habit }) => any;
  id?: number;
};

export const FetchHabit = ({ children, id }: FetchHabitProps): JSX.Element => {
  const [habit, setHabit] = useState<Habit>({ ...blankHabit });
  const lastRefreshed = useUpdateListener('habits');

  useEffect(() => {
    if (!id || !+id) {
      setHabit(blankHabit);
      return;
    }

    let cancelled = false;

    api.service('habits').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setHabit(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching habits: `, error);
        }
        setHabit({ ...blankHabit });
      })

  }, [id, lastRefreshed]);

  return children({ habit });
};

