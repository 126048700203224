import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface User {
  id: number;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  isVerified: boolean;
  createdAt: string;
  updatedAt: string;
  password?: string;
};

export const blankUser: User = {
  id: 0,
  email: '',
  role: '',
  firstName: '',
  lastName: '',
  isVerified: false,
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type UsersStore = typeof initialState;

const users = (state = initialState, action: any): UsersStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default users;
