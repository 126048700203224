import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CustomInput,
  Button,
} from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { StyledSelect } from '../components/StyledSelect';
import { FetchUser } from '../fetchers/FetchUsers';
import { User } from '../state/usersReducer';
import { findOptionByValue } from '../utils';
import { saveUser, deleteUser } from '../state/usersActions';
import api from '../state/api';

const USER_ROLES_OPTIONS = [
  { value: '', label: 'Regular User' },
  { value: 'superuser', label: 'Full Access' },
];

interface ParamProps {
  userid: string;
}

interface Props {}

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class UserDetailScreen extends Component<CombinedProps> {
  onResendVerificationEmail = async (
    event: React.FormEvent<HTMLButtonElement>,
    email: string,
  ) => {
    event.preventDefault();

    if (!email) {
      return;
    }

    try {
      await api.service('user-verification').create({
        action: 'resend-verification',
        email,
      });

      alert("Verification email sent.");
    } catch (e) {
      alert(`Verification email could not be sent: ${e.message ? e.message : String(e)}`);
    }
  };

  renderForm = ({
    getField,
    changeField,
    changeFieldValue,
  }: FormCallbacks<User>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={getField('firstName')}
                onChange={(event) => changeField('firstName', event)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={getField('lastName')}
                onChange={(event) => changeField('lastName', event)}
              />
            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="companyId">Company</Label>
              <Input type="text" name="companyId" id="companyId" value={getField('companyId')} onChange={event => changeField('companyId', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="requestForCompanyName">Request for Company</Label>
              <Input type="text" name="requestForCompanyName" id="requestForCompanyName" value={getField('requestForCompanyName')} onChange={event => changeField('requestForCompanyName', event)} />
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={getField('email')}
                onChange={(event) => changeField('email', event)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="role">Role</Label>
              <StyledSelect
                id="role"
                options={USER_ROLES_OPTIONS}
                value={findOptionByValue(USER_ROLES_OPTIONS, getField('role'))}
                onChange={(newValue: any) =>
                  changeFieldValue('role', null, newValue ? newValue.value : '')
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="password">Password (when creating)</Label>
              <Input
                type="text"
                name="password"
                id="password"
                value={getField('password')}
                onChange={(event) => changeField('password', event)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <Label>Verification</Label>
            <FormGroup check>
              <CustomInput
                type="checkbox"
                name="isVerified"
                id="isVerified"
                checked={getField('isVerified')}
                onChange={(event) => changeField('isVerified', event)}
                label="Is Verified"
              />
            </FormGroup>
            <Button
              onClick={(event) =>
                this.onResendVerificationEmail(event, getField('email') || '')
              }
              disabled={(getField('email') || '').indexOf('@') < 0}
              size="sm"
              color="primary"
              className="mt-2 mb-3">
              Re-send verification email
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input
                readOnly
                type="text"
                name="createdAt"
                id="createdAt"
                value={getField('createdAt')}
                onChange={(event) => changeField('createdAt', event)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input
                readOnly
                type="text"
                name="updatedAt"
                id="updatedAt"
                value={getField('updatedAt')}
                onChange={(event) => changeField('updatedAt', event)}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (
    _detailScreen: DetailScreen<User>,
    original: User,
    changes: Partial<User>,
  ): Promise<User> => {
    return await saveUser(original, changes);
  };

  onDelete = async (
    _detailScreen: DetailScreen<User>,
    original: User,
  ): Promise<void> => {
    return await deleteUser(original);
  };

  render() {
    return (
      <FetchUser id={+this.props.match.params.userid}>
        {({ user }) => (
          <DetailScreen<User>
            original={user}
            isNew={this.props.match.params.userid === 'new'}
            titleField="email"
            titleIfNew="New User"
            route={[{ Users: '/users' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        )}
      </FetchUser>
    );
  }
}

export default UserDetailScreen;
