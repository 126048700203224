import React, { Component } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import PageTitle from '../components/PageTitle';
import raw from 'raw.macro';
import Markdown from 'react-markdown';

const content = raw('./Help.md');

interface Props {
};

interface State {
};

function flatten(text: any, child: any) {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function HeadingRenderer(props: any) {
  var children = React.Children.toArray(props.children)
  var text = children.reduce(flatten, '')
  var slug = text.toLowerCase().replace(/\W/g, '-')
  return React.createElement('h' + props.level, { id: slug }, props.children)
}

class HelpScreen extends Component<Props, State> {
  static title = "Dashboard User's Guide";

  constructor(props: Props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <>
        <PageTitle title={HelpScreen.title} />
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Markdown 
                  source={content} 
                  renderers={{
                    heading: HeadingRenderer,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default HelpScreen;
