import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface UploadGroup {
  id: number;
  title: string;
  subtitle: string;
  uploadIds: string;
  createdAt: string;
  updatedAt: string;
};

export const blankUploadGroup: UploadGroup = {
  id: 0,
  title: '',
  subtitle: '',
  uploadIds: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type UploadGroupsStore = typeof initialState;

const uploadGroups = (state = initialState, action: any): UploadGroupsStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default uploadGroups;
