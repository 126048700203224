import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { FetchUploadGroup } from '../fetchers/FetchUploadGroups';
import { UploadGroup } from '../state/uploadGroupsReducer';
import { deleteUploadGroup, saveUploadGroup } from '../state/uploadGroupsActions';

interface ParamProps {
  uploadgroupid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class UploadGroupDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField }: FormCallbacks<UploadGroup>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="title">Heading</Label>
              <Input type="text" name="title" id="title" value={getField('title')} onChange={event => changeField('title', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="subtitle">Subheading</Label>
              <Input type="text" name="subtitle" id="title" value={getField('subtitle') || ''} onChange={event => changeField('subtitle', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="uploadIds">Upload IDs (comma separated)</Label>
              <Input type="text" name="uploadIds" id="uploadIds" value={getField('uploadIds')} onChange={event => changeField('uploadIds', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<UploadGroup>, original: UploadGroup, changes: Partial<UploadGroup>): Promise<UploadGroup> => {
    return await saveUploadGroup(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<UploadGroup>, original: UploadGroup): Promise<void> => {
    return await deleteUploadGroup(original);
  }

  render() {
    return (
      <FetchUploadGroup id={+this.props.match.params.uploadgroupid}>
        {({ uploadGroup }) =>
          <DetailScreen<UploadGroup>
            original={uploadGroup}
            isNew={this.props.match.params.uploadgroupid === 'new'}
            titleField="id"
            titleIfNew="New Upload Group"
            route={[{ 'Upload Groups': '/uploadgroups' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchUploadGroup>
    );
  }
}

export default UploadGroupDetailScreen;
