const initialState: {
  isLoggedIn: boolean;
  registrationState: '' | 'in_progress' | 'failed' | 'complete';
  registrationError: string;
  token: string;
  loginState: '' | 'in_progress' | 'failed' | 'logged_in';
  loginError: string;
  loginReturnTo: string;
  resendVerificationTokenState: '' | 'in_progress' | 'failed' | 'complete';
  resendVerificationTokenError: string;
  verifyTokenState: '' | 'in_progress' | 'failed' | 'verified';
  verifyTokenError: string;
  verifiedToken: string;
  passwordResetRequestState: '' | 'in_progress' | 'failed' | 'complete';
  passwordResetState: '' | 'in_progress' | 'failed' | 'complete';
  user: any;
} = {
  isLoggedIn: false,
  registrationState: '',
  registrationError: '',
  token: '',
  loginState: '',
  loginError: '',
  loginReturnTo: '/',
  resendVerificationTokenState: '',
  resendVerificationTokenError: '',
  verifyTokenState: '',
  verifyTokenError: '',
  verifiedToken: '',
  passwordResetRequestState: '',
  passwordResetState: '',
  user: {},
};

export type LoginStore = typeof initialState;

export const LOGGING_IN = 'LOGGING_IN';
export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const ATTEMPTING_REGISTRATION = 'ATTEMPTING_REGISTRATION';
export const FAILED_REGISTRATION = 'FAILED_REGISTRATION';
export const COMPLETED_REGISTRATION = 'COMPLETED_REGISTRATION';
export const ATTEMPTING_LOGIN = 'ATTEMPTING_LOGIN';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const COMPLETED_LOGIN = 'COMPLETED_LOGIN';
export const ATTEMPTING_RESEND_VERIFICATION_TOKEN = 'ATTEMPTING_RESEND_VERIFICATION_TOKEN';
export const FAILED_RESEND_VERIFICATION_TOKEN = 'FAILED_RESEND_VERIFICATION_TOKEN';
export const COMPLETED_RESEND_VERIFICATION_TOKEN = 'COMPLETED_RESEND_VERIFICATION_TOKEN';
export const ATTEMPTING_VERIFY_TOKEN = 'ATTEMPTING_VERIFY_TOKEN';
export const FAILED_VERIFY_TOKEN = 'FAILED_VERIFY_TOKEN';
export const COMPLETED_VERIFY_TOKEN = 'COMPLETED_VERIFY_TOKEN';
export const ATTEMPTING_PASSWORD_RESET_REQUEST = 'ATTEMPTING_PASSWORD_RESET_REQUEST';
export const FAILED_PASSWORD_RESET_REQUEST = 'FAILED_PASSWORD_RESET_REQUEST';
export const COMPLETED_PASSWORD_RESET_REQUEST = 'COMPLETED_PASSWORD_RESET_REQUEST';
export const ATTEMPTING_PASSWORD_RESET = 'ATTEMPTING_PASSWORD_RESET';
export const FAILED_PASSWORD_RESET = 'FAILED_PASSWORD_RESET';
export const COMPLETED_PASSWORD_RESET = 'COMPLETED_PASSWORD_RESET';

const resetOnLoad: Partial<LoginStore> = {
  loginState: '',
  registrationState: '',
  resendVerificationTokenState: '',
  verifyTokenState: '',
  passwordResetRequestState: '',
  passwordResetState: '',
};

const login = (state = initialState, action: any): LoginStore => {
  switch (action.type) {
    case 'RESET_ON_LOAD':
      return { ...state, ...resetOnLoad };

    case LOGGING_IN:
      return { ...state };

    case LOGGING_OUT:
      return { ...state };

    case LOGGED_IN:
      return { ...state, isLoggedIn: true, loginState: 'logged_in', loginError: '' };

    case LOGGED_OUT:
      return { ...initialState };


    case ATTEMPTING_REGISTRATION:
      return { ...state, registrationState: 'in_progress', registrationError: '' };

    case FAILED_REGISTRATION:
      return { ...state, registrationState: 'failed', registrationError: action.error };

    case COMPLETED_REGISTRATION:
      return { ...state, registrationState: 'complete', registrationError: '' };


    case ATTEMPTING_LOGIN:
      return { ...state, loginState: 'in_progress', loginError: '' };

    case FAILED_LOGIN:
      return { ...state, loginState: 'failed', loginError: action.error };

    case COMPLETED_LOGIN:
      // We don't set isLoggedIn until LOGGED_IN
      return { ...state, loginError: '', token: action.token, user: action.user };


    case ATTEMPTING_RESEND_VERIFICATION_TOKEN:
      return { ...state, resendVerificationTokenState: 'in_progress', resendVerificationTokenError: '' };

    case FAILED_RESEND_VERIFICATION_TOKEN:
      return { ...state, resendVerificationTokenState: 'failed', resendVerificationTokenError: action.error };

    case COMPLETED_RESEND_VERIFICATION_TOKEN:
      return { ...state, resendVerificationTokenState: 'complete', resendVerificationTokenError: '', verifiedToken: action.token };


    case ATTEMPTING_VERIFY_TOKEN:
      return { ...state, verifyTokenState: 'in_progress', verifyTokenError: '' };

    case FAILED_VERIFY_TOKEN:
      return { ...state, verifyTokenState: 'failed', verifyTokenError: action.error };

    case COMPLETED_VERIFY_TOKEN:
      return { ...state, verifyTokenState: 'verified', verifyTokenError: '', verifiedToken: action.token };


    case ATTEMPTING_PASSWORD_RESET_REQUEST:
      return { ...state, passwordResetRequestState: 'in_progress' };

    case FAILED_PASSWORD_RESET_REQUEST:
      return { ...state, passwordResetRequestState: 'failed' };

    case COMPLETED_PASSWORD_RESET_REQUEST:
      return { ...state, passwordResetRequestState: 'complete' };


    case ATTEMPTING_PASSWORD_RESET:
      return { ...state, passwordResetState: 'in_progress' };

    case FAILED_PASSWORD_RESET:
      return { ...state, passwordResetState: 'failed' };

    case COMPLETED_PASSWORD_RESET:
      return { ...state, passwordResetState: 'complete' };


    default:
      return state;
  }
};

export default login;
