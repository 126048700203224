import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { FetchUpload } from '../fetchers/FetchUploads';
import UserSelect from '../selects/UserSelect';
import { REST_ROOT } from '../state/api';
import { Upload } from '../state/uploadsReducer';
import { pathJoin } from '../utils';
import { saveUpload, deleteUpload } from '../state/uploadsActions';

interface ParamProps {
  uploadid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

export const fixUploadUri = (uri: string): string => {
  if (uri.indexOf('/') >= 0) {
    return pathJoin(REST_ROOT, 'public', uri.replace(/ /g, '%20'));
  } else {
    return pathJoin(REST_ROOT, 'public/uploads', uri.replace(/ /g, '%20'));
  }
};


class UploadDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField, changeFileField, changeFieldValue }: FormCallbacks<Upload>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="id">ID</Label>
              <Input readOnly type="text" name="id" id="id" value={getField('id')} onChange={event => changeField('id', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="canonicalLink">Link</Label>
              <Input readOnly type="text" name="canonicalLink" id="canonicalLink" value={fixUploadUri(getField('uri'))} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input type="text" name="description" id="description" value={getField('description')} onChange={event => changeField('description', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="userId">Uploader</Label>
              <UserSelect
                id="userId"
                value={getField('userId')}
                onChange={newValue => changeFieldValue('userId', null, newValue)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="originalName">Original Name</Label>
              <Input type="text" name="originalName" id="originalName" value={getField('originalName')} onChange={event => changeField('originalName', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="uri">Location</Label>
              <Input type="text" name="uri" id="uri" value={getField('uri')} onChange={event => changeField('uri', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="viewAs">View As Extension (e.g., pdf)</Label>
              <Input type="text" name="viewAs" id="viewAs" value={getField('viewAs')} onChange={event => changeField('viewAs', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="link">Open Link instead of File</Label>
              <Input type="text" name="link" id="link" value={getField('link')} onChange={event => changeField('link', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="files">File</Label>
              <Input type="file" name="files" id="files" onChange={event => changeFileField('files', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<Upload>, original: Upload, changes: Partial<Upload>): Promise<Upload> => {
    return await saveUpload(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<Upload>, original: Upload): Promise<void> => {
    return await deleteUpload(original);
  }

  render() {
    return (
      <FetchUpload id={+this.props.match.params.uploadid}>
        {({ upload }) =>
          <DetailScreen<Upload>
            original={upload}
            isNew={this.props.match.params.uploadid === 'new'}
            titleField="originalName"
            titleIfNew="New Upload"
            route={[{ 'Uploads': '/uploads' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchUpload>
    );
  }
}

export default UploadDetailScreen;