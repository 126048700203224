import moment from 'moment';

export const IS_IE =
  typeof window !== 'undefined' &&
  !!window.MSInputMethodContext &&
  !!(document as any).documentMode;

// This will be used if using a hardcoded API root, otherwise it'll be replaced by generateAutoAPIRoot
export const API_PATH = `sisu-api/v1`;

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const USE_SOCKETIO = IS_PRODUCTION && !IS_IE; // Easier to debug when you can see requests in the browser developer tools

export const ENABLE_LOGGING = !IS_PRODUCTION;

const STATE_VERSION = 5;
export const STATE_KEY = `sisu_dashboard_v${STATE_VERSION}`;

export const DATE_TIME_FORMAT = `DD/MM/YYYY HH:mm`;

export const stringToDateTime = (input: string): string => {
  return '' + moment(input, DATE_TIME_FORMAT).toDate();
}

export const dateTimeToString = (input: string|number): string => {
  return moment(input).format(DATE_TIME_FORMAT);
}
