import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Card, Col, Container, Row } from 'reactstrap';
import { LogoCardBody, LogoCardHeader } from '../components/LogoCardHeader';
import { postVerifyToken } from '../state/loginActions';
import { CombinedStore } from '../state/rootReducer';

interface ParamProps {
  token: string;
};

interface StateProps {
  verifyTokenState: string,
};

interface DispatchProps {
  postVerifyToken: (token: string) => void;
};

interface Props {
};

type CombinedProps = RouteComponentProps<ParamProps> & Props & StateProps & DispatchProps;

interface State {
};

class VerifyTokenScreen extends Component<CombinedProps, State> {

  componentDidMount() {
    this.submitToken();
  }

  componentDidUpdate(prevProps: CombinedProps) {
    if (this.props.match.params.token !== prevProps.match.params.token) {
      this.submitToken();
    }
  }

  submitToken = () => {
    const token = this.props.match.params.token;
    if (token.trim() === '') {
      return;
    }

    this.props.postVerifyToken(token);
  }

  render() {
    const { verifyTokenState } = this.props;

    return (
      <Container className="mt-5 mb-5 VerifyTokenScreen">
        <Row className="justify-content-center">
          <Col lg={5}>
            <Card>
              <LogoCardHeader />
              <LogoCardBody className="p-4">
                {verifyTokenState === 'in_progress'
                  ? <h4>Please wait...</h4>
                  : verifyTokenState === 'failed'
                    ? <>
                      <h4>We were unable to record your verification</h4>
                      <p>It could be that too much time has passed since we sent the email,
                        or you're email address has already been verified. Please try again.</p>
                    </>
                    : verifyTokenState === 'verified'
                      ? <>
                        <h4>Your email address has now been verified!</h4>
                        <p>Please return to the app and sign in</p>
                      </>
                      : null
                }
              </LogoCardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: CombinedStore): StateProps => ({
  verifyTokenState: state.login.verifyTokenState,
});

const mapDispatchToProps = (dispatch: (action: any) => void): DispatchProps => ({
  postVerifyToken: (token) => dispatch(postVerifyToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyTokenScreen);
