import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { FetchCourse } from '../fetchers/FetchCourses';
import { Course } from '../state/coursesReducer';
import { saveCourse, deleteCourse } from '../state/coursesActions';

interface ParamProps {
  courseid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class CourseDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField }: FormCallbacks<Course>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input type="text" name="title" id="title" value={getField('title')} onChange={event => changeField('title', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="uploadGroupIds">Knowledge Centre Upload Group IDs (comma separated)</Label>
              <Input type="text" name="uploadGroupIds" id="uploadGroupIds" value={getField('uploadGroupIds')} onChange={event => changeField('uploadGroupIds', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<Course>, original: Course, changes: Partial<Course>): Promise<Course> => {
    return await saveCourse(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<Course>, original: Course): Promise<void> => {
    return await deleteCourse(original);
  }

  render() {
    return (
      <FetchCourse id={+this.props.match.params.courseid}>
        {({ course }) =>
          <DetailScreen<Course>
            original={course}
            isNew={this.props.match.params.courseid === 'new'}
            titleField="title"
            titleIfNew="New Course"
            route={[{ 'Courses': '/courses' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchCourse>
    );
  }
}

export default CourseDetailScreen;
