import React from 'react';
import { CardBody, CardHeader } from 'reactstrap';
import logo from '../media/logo.png';

export const LogoCardHeader = (props: any): JSX.Element => {
  const { style, ...rest } = props;
  return (
    <CardHeader className="text-center bg-white px-3 py-3" style={{ ...style || {} }} {...rest}>
      <img src={logo} width="100%" style={{ maxWidth: 202 }} alt="Logo" />
      <div style={{ minWidth: 400 }}/>
    </CardHeader>
  );
};

export const LogoCardBody = (props: any): JSX.Element => {
  const { style, children, ...rest } = props;

  return (
    <CardBody {...rest} style={{ ...style || {}, maxWidth: 448 }}>
      {props.children}
    </CardBody>
  );
};
