import { useEffect, useState } from 'react';
import api from '../state/api';
import { Cohort, blankCohort } from '../state/cohortsReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchCohortsResults {
  cohorts?: Cohort[];
  numberOfPages: number;
  numberOfCohorts?: number;
  totalNumberOfCohorts?: number;
};

interface FetchCohortsProps {
  children: (results: FetchCohortsResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchCohorts = ({ children, page, search, query }: FetchCohortsProps): JSX.Element => {
  const [results, setResults] = useState<FetchCohortsResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('cohorts');

  useEffect(() => {
    let cancelled = false;

    api.service('cohorts').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfCohorts: json.total,
            numberOfPages: json.total / json.limit,
            numberOfCohorts: json.data.length,
            cohorts: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching cohorts: `, error);
        }
      });

    return () => { cancelled = true; };
  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchCohortProps {
  children: ({ cohort }: { cohort: Cohort }) => any;
  id?: number;
};

export const FetchCohort = ({ children, id }: FetchCohortProps): JSX.Element => {
  const [cohort, setCohort] = useState<Cohort>({ ...blankCohort });
  const lastRefreshed = useUpdateListener('cohorts');

  useEffect(() => {
    if (!id || !+id) {
      setCohort(blankCohort);
      return;
    }

    let cancelled = false;

    api.service('cohorts').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setCohort(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching cohorts: `, error);
        }
        setCohort({ ...blankCohort });
      });

    return () => { cancelled = true; };
  }, [id, lastRefreshed]);

  return children({ cohort });
};

