import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { StyledSelect } from '../components/StyledSelect';
import { FetchCohortFeedInteraction } from '../fetchers/FetchCohortFeedInteractions';
import UserSelect from '../selects/UserSelect';
import { CohortFeedInteraction } from '../state/cohortFeedInteractionsReducer';
import { findOptionByValue } from '../utils';
import { saveCohortFeedInteraction, deleteCohortFeedInteraction } from '../state/cohortFeedInteractionsActions';

const INTERACTION_OPTIONS = [
  { value: '', label: "Comment" },
  { value: 'like', label: "Like" },
];

interface ParamProps {
  cohortfeedinteractionid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class CohortFeedInteractionDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField, changeFieldValue }: FormCallbacks<CohortFeedInteraction>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="feedItemId">Cohort Feed Item</Label>
              <Input type="text" name="feedItemId" id="feedItemId" value={getField('feedItemId')} onChange={event => changeField('feedItemId', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="userId">User</Label>
              <UserSelect
                id="userId"
                value={getField('userId')}
                onChange={newValue => changeFieldValue('userId', null, newValue)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="type">Type</Label>
              <StyledSelect
                id="type"
                options={INTERACTION_OPTIONS}
                value={findOptionByValue(INTERACTION_OPTIONS, getField('type'))}
                onChange={(newValue: any) => changeFieldValue('type', null, newValue ? newValue.value : '')}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="comment">Comment</Label>
              <Input type="textarea" style={{ height: '10em' }} name="comment" id="comment" value={getField('comment')} onChange={event => changeField('comment', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<CohortFeedInteraction>, original: CohortFeedInteraction, changes: Partial<CohortFeedInteraction>): Promise<CohortFeedInteraction> => {
    return await saveCohortFeedInteraction(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<CohortFeedInteraction>, original: CohortFeedInteraction): Promise<void> => {
    return await deleteCohortFeedInteraction(original);
  }

  render() {
    return (
      <FetchCohortFeedInteraction id={+this.props.match.params.cohortfeedinteractionid}>
        {({ cohortFeedInteraction }) =>
          <DetailScreen<CohortFeedInteraction>
            original={cohortFeedInteraction}
            isNew={this.props.match.params.cohortfeedinteractionid === 'new'}
            titleField="id"
            titleIfNew="Create Cohort Feed Interaction"
            route={[{ 'Cohort Feeds': '/cohortfeedinteractions' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchCohortFeedInteraction>
    );
  }
}

export default CohortFeedInteractionDetailScreen;
