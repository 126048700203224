import api from './api';
import { blankUploadGroup, UploadGroup } from './uploadGroupsReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveUploadGroup(original: UploadGroup, changes: Partial<UploadGroup>): Promise<UploadGroup> {
  const uploadGroup: UploadGroup = { ...blankUploadGroup, ...original, ...changes };
  const params = {
    query: {
      $admin: true,
    },
  };

  const newRecord = uploadGroup.id
    ? await api.service('upload-groups').patch(uploadGroup.id, changes, params)
    : await api.service('upload-groups').create(uploadGroup, params);
  notifyUpdateListeners('upload-groups');
  return newRecord;
}

export async function deleteUploadGroup(original: UploadGroup) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('upload-groups').remove(original.id, params);
  notifyUpdateListeners('upload-groups');
}

