import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface CohortFeedInteraction {
  id: number;
  feedItemId: number;
  userId: number;
  type: '' | 'like';
  comment: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    firstName: string;
    lastName: string;
  };
};

export const blankCohortFeedInteraction: CohortFeedInteraction = {
  id: 0,
  feedItemId: 0,
  userId: 0,
  comment: '',
  type: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type CohortFeedInteractionsStore = typeof initialState;

const cohortFeedInteractions = (state = initialState, action: any): CohortFeedInteractionsStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default cohortFeedInteractions;
