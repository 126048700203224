import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface AppSetting {
  id: number;
  key: string;
  value: string;
  createdAt: string;
  updatedAt: string;
};

export const blankAppSetting: AppSetting = {
  id: 0,
  key: '',
  value: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type AppSettingsStore = typeof initialState;

const appSettings = (state = initialState, action: any): AppSettingsStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default appSettings;
