import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface Upload {
  id: number;
  userId: number;
  description: string;
  originalName: string;
  uri: string;
  viewAs?: string;
  link?: string;
  createdAt: string;
  updatedAt: string;
  files?: any;
  user?: {
    firstName: string;
    lastName: string;
  };
};

export const blankUpload: Upload = {
  id: 0,
  userId: 0,
  description: '',
  originalName: '',
  uri: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type UploadsStore = typeof initialState;

const uploads = (state = initialState, action: any): UploadsStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default uploads;
