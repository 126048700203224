import { LOGGED_OUT } from './loginReducer';

/// Anything with a ? was added in a later API version
export interface CohortMember {
  id: number;
  userId: number;
  cohortId: number;
  createdAt: string;
  updatedAt: string;
  user?: {
    firstName: string;
    lastName: string;
  };
};

export const blankCohortMember: CohortMember = {
  id: 0,
  userId: 0,
  cohortId: 0,
  createdAt: '',
  updatedAt: '',
};

const initialState: {
  changeCount: number;
} = {
  changeCount: 0,
};

export type CohortMembersStore = typeof initialState;

const cohortMembers = (state = initialState, action: any): CohortMembersStore => {
  switch (action.type) {
    case LOGGED_OUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default cohortMembers;
