import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import LoginScreen from '../screens/LoginScreen';
import { CombinedStore } from '../state/rootReducer';

interface Props {
  children: any;
}

interface StateProps {
  isLoggedIn: boolean;
}

interface DispatchProps {
}

interface ParamProps {
}

type CombinedProps = RouteComponentProps<ParamProps> & Props & StateProps & DispatchProps;

class RequireLogin extends Component<CombinedProps> {
  render() {
    return (
      this.props.isLoggedIn
        ? this.props.children
        : <LoginScreen />
    );
  }
}

const mapStateToProps = (state: CombinedStore): StateProps => ({
  isLoggedIn: state.login.isLoggedIn,
});

const mapDispatchToProps = (_dispatch: (action: any) => void): DispatchProps => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequireLogin));
