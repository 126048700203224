import React, { Component } from 'react';
import TableScreen, { Column, TableFilter } from '../components/TableScreen';
import { FetchHabits } from '../fetchers/FetchHabits';
import { Habit } from '../state/habitsReducer';
import UserSelect from '../selects/UserSelect';


interface Props {
};

interface State {
  page: number;
  search: string;
  filterUserId?: number;
};

class HabitListScreen extends Component<Props, State> {
  static title = "Habits";
  columns: Column[];

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 0,
      search: '',
    };

    this.columns = [
      { title: "Title" },
      { title: "Repeat" },
      { title: "Score" },
    ];
  }

  onSelectPage = (page: number) => {
    this.setState({ page });
  }

  onSearchChange = (search: string) => {
    this.setState({ search });
  }

  render() {
    return (
      <FetchHabits
        page={this.state.page}
        search={this.state.search}
        query={{
          userId: this.state.filterUserId || undefined,
        }}
      >
        {({ habits, numberOfPages }) =>
          <TableScreen
            title={(this.constructor as any).title}
            singular="Habit"
            columns={this.columns}
            list={habits}
            row={(item: Habit) => [item.title, item.repeat, item.monthlyScore || 0]}
            rowId={(item: Habit) => `${item.id}`}
            detailPath="/habits/:id"
            currentPage={this.state.page}
            numberOfPages={numberOfPages}
            onSelectPage={this.onSelectPage}
            search={this.state.search}
            onSearchChange={this.onSearchChange}
            renderFilters={() =>
              <>
                <TableFilter label="User" maxWidth={30}>
                  <UserSelect
                    value={this.state.filterUserId}
                    onChange={filterUserId => this.setState({ filterUserId })}
                  />
                </TableFilter>
              </>
            }
          />
        }
      </FetchHabits>
    );
  }
}

export default HabitListScreen;
