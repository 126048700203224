import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { Button, Card, CardFooter, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { LogoCardBody, LogoCardHeader } from '../components/LogoCardHeader';
import { postLogin } from '../state/loginActions';
import { CombinedStore } from '../state/rootReducer';


interface StateProps {
  isLoggedIn: boolean,
  loginState: string,
};

interface DispatchProps {
  postLogin?: (email: string, password: string) => void;
};

interface Props {
};

type CombinedProps = Props & StateProps & DispatchProps & RouteComponentProps<{}>;

interface State {
  email: string;
  password: string;
};

class LoginScreen extends Component<CombinedProps, State> {
  constructor(props: CombinedProps) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };
  }

  emailChanged = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ email: event.currentTarget.value });
  }

  passwordChanged = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password: event.currentTarget.value });
  }

  login = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.props.postLogin && this.props.postLogin(this.state.email, this.state.password);
  }

  componentDidUpdate(prevProps: CombinedProps) {
    if (prevProps.loginState !== this.props.loginState) {
      if (this.props.loginState === 'logged_in') {
        // this.props.onLoggedIn(this.props.loginReturnTo);
        return;
      }
    }
  }

  render() {
    return (
      <Container className="mt-5 mb-5 LoginScreen">
        <Row className="justify-content-center">
          <Col xs="auto">
            <Card className="position-relative">
              <LogoCardHeader />
              <LogoCardBody className="p-4">
                <Form>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input type="email" name="email" onChange={this.emailChanged} value={this.state.email} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input type="password" name="password" onChange={this.passwordChanged} value={this.state.password} />
                  </FormGroup>
                </Form>
                <div className="text-center mt-4">
                  <Button color="primary" onClick={this.login}>Sign In</Button>
                </div>
              </LogoCardBody>
              <CardFooter className="text-center">
                <div><Link to="/register">Create an account</Link></div>
                <div className="mt-2"><Link to="/resetpassword">Forgotten your password?</Link></div>
              </CardFooter>
              {this.props.loginState === 'in_progress' && <LoadingOverlay />}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: CombinedStore): StateProps => ({
  isLoggedIn: state.login.isLoggedIn,
  loginState: state.login.loginState,
});

const mapDispatchToProps = (dispatch: (action: any) => void): DispatchProps => ({
  postLogin: (email, password) => dispatch(postLogin(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginScreen));
