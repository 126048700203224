import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { FetchCohortFeedItem } from '../fetchers/FetchCohortFeedItems';
import CohortSelect from '../selects/CohortSelect';
import UserSelect from '../selects/UserSelect';
import { CohortFeedItem } from '../state/cohortFeedItemsReducer';
import { saveCohortFeedItem, deleteCohortFeedItem } from '../state/cohortFeedItemsActions';

interface ParamProps {
  cohortfeeditemid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class CohortFeedItemDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField, changeFieldValue }: FormCallbacks<CohortFeedItem>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="cohortId">Cohort</Label>
              <CohortSelect
                id="cohortId"
                value={getField('cohortId')}
                onChange={newValue => changeFieldValue('cohortId', null, newValue)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="userId">User</Label>
              <UserSelect
                id="userId"
                value={getField('userId')}
                onChange={newValue => changeFieldValue('userId', null, newValue)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="text">Text</Label>
              <Input type="textarea" style={{ height: '10em' }} name="text" id="text" value={getField('text')} onChange={event => changeField('text', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="stickyDays">Sticky Days</Label>
              <Input type="text" name="stickyDays" id="stickyDays" value={getField('stickyDays')} onChange={event => changeField('stickyDays', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="commentCount">Comment Count</Label>
              <Input readOnly type="text" name="commentCount" id="commentCount" value={getField('commentCount')} onChange={event => changeField('commentCount', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="likeCount">Like Count</Label>
              <Input readOnly type="text" name="likeCount" id="likeCount" value={getField('likeCount')} onChange={event => changeField('likeCount', event)} />
            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="patientId">Patient ID</Label>
              <Input readOnly type="text" name="patientId" id="patientId" value={getField('patientId')} onChange={event => changeField('patientId', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="legLengthDiscrepancy">legLengthDiscrepancy</Label>
              <Input type="text" name="legLengthDiscrepancy" id="legLengthDiscrepancy" value={getField('legLengthDiscrepancy')} onChange={event => changeField('legLengthDiscrepancy', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="legLengthDiscrepancySide">legLengthDiscrepancySide</Label>
              <Input type="text" name="legLengthDiscrepancySide" id="legLengthDiscrepancySide" value={getField('legLengthDiscrepancySide')} onChange={event => changeField('legLengthDiscrepancySide', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="footLengthLeft">footLengthLeft</Label>
              <Input type="text" name="footLengthLeft" id="footLengthLeft" value={getField('footLengthLeft')} onChange={event => changeField('footLengthLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="footLengthRight">footLengthRight</Label>
              <Input type="text" name="footLengthRight" id="footLengthRight" value={getField('footLengthRight')} onChange={event => changeField('footLengthRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="mtpMeasurementLeft">mtpMeasurementLeft</Label>
              <Input type="text" name="mtpMeasurementLeft" id="mtpMeasurementLeft" value={getField('mtpMeasurementLeft')} onChange={event => changeField('mtpMeasurementLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="mtpMeasurementRight">mtpMeasurementRight</Label>
              <Input type="text" name="mtpMeasurementRight" id="mtpMeasurementRight" value={getField('mtpMeasurementRight')} onChange={event => changeField('mtpMeasurementRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="foreFootWidthLeft">foreFootWidthLeft</Label>
              <Input type="text" name="foreFootWidthLeft" id="foreFootWidthLeft" value={getField('foreFootWidthLeft')} onChange={event => changeField('foreFootWidthLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="foreFootWidthRight">foreFootWidthRight</Label>
              <Input type="text" name="foreFootWidthRight" id="foreFootWidthRight" value={getField('foreFootWidthRight')} onChange={event => changeField('foreFootWidthRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="backFootWidthLeft">backFootWidthLeft</Label>
              <Input type="text" name="backFootWidthLeft" id="backFootWidthLeft" value={getField('backFootWidthLeft')} onChange={event => changeField('backFootWidthLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="backFootWidthRight">backFootWidthRight</Label>
              <Input type="text" name="backFootWidthRight" id="backFootWidthRight" value={getField('backFootWidthRight')} onChange={event => changeField('backFootWidthRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="instepHeightLeft">instepHeightLeft</Label>
              <Input type="text" name="instepHeightLeft" id="instepHeightLeft" value={getField('instepHeightLeft')} onChange={event => changeField('instepHeightLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="instepHeightRight">instepHeightRight</Label>
              <Input type="text" name="instepHeightRight" id="instepHeightRight" value={getField('instepHeightRight')} onChange={event => changeField('instepHeightRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="instepCircumferenceLeft">instepCircumferenceLeft</Label>
              <Input type="text" name="instepCircumferenceLeft" id="instepCircumferenceLeft" value={getField('instepCircumferenceLeft')} onChange={event => changeField('instepCircumferenceLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="instepCircumferenceRight">instepCircumferenceRight</Label>
              <Input type="text" name="instepCircumferenceRight" id="instepCircumferenceRight" value={getField('instepCircumferenceRight')} onChange={event => changeField('instepCircumferenceRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="circBunionLeft">circBunionLeft</Label>
              <Input type="text" name="circBunionLeft" id="circBunionLeft" value={getField('circBunionLeft')} onChange={event => changeField('circBunionLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="circBunionRight">circBunionRight</Label>
              <Input type="text" name="circBunionRight" id="circBunionRight" value={getField('circBunionRight')} onChange={event => changeField('circBunionRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="circBelowKneeLeft">circBelowKneeLeft</Label>
              <Input type="text" name="circBelowKneeLeft" id="circBelowKneeLeft" value={getField('circBelowKneeLeft')} onChange={event => changeField('circBelowKneeLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="circBelowKneeRight">circBelowKneeRight</Label>
              <Input type="text" name="circBelowKneeRight" id="circBelowKneeRight" value={getField('circBelowKneeRight')} onChange={event => changeField('circBelowKneeRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="ankleWidthLeft">ankleWidthLeft</Label>
              <Input type="text" name="ankleWidthLeft" id="ankleWidthLeft" value={getField('ankleWidthLeft')} onChange={event => changeField('ankleWidthLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="ankleWidthRight">ankleWidthRight</Label>
              <Input type="text" name="ankleWidthRight" id="ankleWidthRight" value={getField('ankleWidthRight')} onChange={event => changeField('ankleWidthRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="circAbvJumpJointLeft">circAbvJumpJointLeft</Label>
              <Input type="text" name="circAbvJumpJointLeft" id="circAbvJumpJointLeft" value={getField('circAbvJumpJointLeft')} onChange={event => changeField('circAbvJumpJointLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="circAbvJumpJointRight">circAbvJumpJointRight</Label>
              <Input type="text" name="circAbvJumpJointRight" id="circAbvJumpJointRight" value={getField('circAbvJumpJointRight')} onChange={event => changeField('circAbvJumpJointRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="circBetweenLeft">circBetweenLeft</Label>
              <Input type="text" name="circBetweenLeft" id="circBetweenLeft" value={getField('circBetweenLeft')} onChange={event => changeField('circBetweenLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="circBetweenRight">circBetweenRight</Label>
              <Input type="text" name="circBetweenRight" id="circBetweenRight" value={getField('circBetweenRight')} onChange={event => changeField('circBetweenRight', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="circHighestCalfLeft">circHighestCalfLeft</Label>
              <Input type="text" name="circHighestCalfLeft" id="circHighestCalfLeft" value={getField('circHighestCalfLeft')} onChange={event => changeField('circHighestCalfLeft', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="circHighestCalfRight">circHighestCalfRight</Label>
              <Input type="text" name="circHighestCalfRight" id="circHighestCalfRight" value={getField('circHighestCalfRight')} onChange={event => changeField('circHighestCalfRight', event)} />
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<CohortFeedItem>, original: CohortFeedItem, changes: Partial<CohortFeedItem>): Promise<CohortFeedItem> => {
    return await saveCohortFeedItem(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<CohortFeedItem>, original: CohortFeedItem): Promise<void> => {
    return await deleteCohortFeedItem(original);
  }

  render() {
    return (
      <FetchCohortFeedItem id={+this.props.match.params.cohortfeeditemid}>
        {({ cohortFeedItem }) =>
          <DetailScreen<CohortFeedItem>
            original={cohortFeedItem}
            isNew={this.props.match.params.cohortfeeditemid === 'new'}
            titleField="id"
            titleIfNew="Create Cohort Feed Item"
            route={[{ 'Cohort Feeds': '/cohortfeeditems' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchCohortFeedItem>
    );
  }
}

export default CohortFeedItemDetailScreen;
