import { useEffect, useState } from 'react';
import api from '../state/api';
import { CohortMember, blankCohortMember } from '../state/cohortMembersReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchCohortMembersResults {
  cohortMembers?: CohortMember[];
  numberOfPages: number;
  numberOfCohortMembers?: number;
  totalNumberOfCohortMembers?: number;
};

interface FetchCohortMembersProps {
  children: (results: FetchCohortMembersResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchCohortMembers = ({ children, page, search, query }: FetchCohortMembersProps): JSX.Element => {
  const [results, setResults] = useState<FetchCohortMembersResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('cohort-members');

  useEffect(() => {
    let cancelled = false;

    api.service('cohort-members').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfCohortMembers: json.total,
            numberOfPages: json.total / json.limit,
            numberOfCohortMembers: json.data.length,
            cohortMembers: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching cohortMembers: `, error);
        }
      });

    return () => { cancelled = true; };
  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchCohortMemberProps {
  children: ({ cohortMember }: { cohortMember: CohortMember }) => any;
  id?: number;
};

export const FetchCohortMember = ({ children, id }: FetchCohortMemberProps): JSX.Element => {
  const [cohortMember, setCohortMember] = useState<CohortMember>({ ...blankCohortMember });
  const lastRefreshed = useUpdateListener('cohort-members');

  useEffect(() => {
    if (!id || !+id) {
      setCohortMember(blankCohortMember);
      return;
    }

    let cancelled = false;

    api.service('cohort-members').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setCohortMember(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching cohortMembers: `, error);
        }
        setCohortMember({ ...blankCohortMember });
      });

    return () => { cancelled = true; };
  }, [id, lastRefreshed]);

  return children({ cohortMember });
};

