import _ from 'lodash';

export function convertKeysToJSON<Type>(object: Type, keys: (keyof Type)[]) {
  for (const key of keys) {
    if ((object as any)[key]) {
      (object as any)[key] = JSON.stringify((object as any)[key]);
    }
  }
}

export function keyedById<Type>(array: Type[], idKey: keyof Type): { [id: string]: Type } {
  const byId: { [id: string]: Type } = {};
  for (const element of array) {
    byId[(element as any)[idKey as string]] = element;
  }

  return byId;
}

export function removeObjectFromArraysKeyedByForeignId<Type>(
  input: { [id: string]: Type[] },
  foreignIdKey: keyof Type,
  idKey: keyof Type,
  remove: Type)
  : { [id: string]: Type[] } {
  const removingForeignId = (remove as any)[foreignIdKey];
  const removingId = (remove as any)[idKey];

  const output = _.pickBy(input, (_value, foreignId) => foreignId !== removingForeignId);
  output[removingForeignId] = _.filter(
    input[removingForeignId] || [], 
    item => (item as any)[idKey] !== removingId);

  return output;
}

export function mergeObjectInToArraysKeyedByForeignId<Type>(
  input: { [id: string]: Type[] },
  foreignIdKey: keyof Type,
  idKey: keyof Type,
  merge: Type,
  removeFirst?: Type)
  : { [id: string]: Type[] } {

  if (removeFirst !== undefined) {
    return mergeObjectInToArraysKeyedByForeignId<Type>(
      removeObjectFromArraysKeyedByForeignId(input, foreignIdKey, idKey, removeFirst),
      foreignIdKey,
      idKey,
      merge);
  }

  const mergingForeignId = (merge as any)[foreignIdKey];
  const mergingId = (merge as any)[idKey];

  const output = _.pickBy(input, (_value, foreignId) => foreignId !== mergingForeignId);
  output[mergingForeignId] = _.filter(
    input[mergingForeignId] || [], 
    item => (item as any)[idKey] !== mergingId);
  output[mergingForeignId].push(merge);
  
  return output;
}

function testMergeMapOfObjectsByForeignId() {
  interface Thing {
    id: string;
    foreignId: string;
    value: string;
  };
  const original: { [id: string]: Thing[] } = {
    '12': [
      { id: '1', foreignId: '12', value: 'a' },
      { id: '2', foreignId: '12', value: 'b' },
      { id: '3', foreignId: '12', value: 'c' },
    ],
  };
  const merge: Thing = { id: '2', foreignId: '12', value: 'z' };
  const merged = mergeObjectInToArraysKeyedByForeignId<Thing>(original, 'foreignId', 'id', merge);
  if (merged['12'][0].id !== '1' || merged['12'][0].foreignId !== '12' || merged['12'][0].value !== 'a') {
    throw Error('fail');
  }
  if (merged['12'][1].id !== '3' || merged['12'][1].foreignId !== '12' || merged['12'][1].value !== 'c') {
    throw Error('fail');
  }
  if (merged['12'][2].id !== '2' || merged['12'][2].foreignId !== '12' || merged['12'][2].value !== 'z') {
    throw Error('fail');
  }
}

testMergeMapOfObjectsByForeignId();

export function closest(element: Node | null, tag: string) {
  const upperTag = tag.toUpperCase();
  while (element) {
    if (element instanceof Element && element.tagName === upperTag) {
      return element;
    }

    element = element.parentNode;
  }

  return null;
}

export function arrayToLines(array: string[]): string {
  return Array.isArray(array) ? array.join('\r\n') : array;
}

export function arrayFromLines(lines: string): string[] {
  return lines.split(/[\r\n]+/);
}

export function arrayToCommas(array: string[]) {
  return array.join(',');
}

export function arrayFromCommas(commas: string): string[] {
  return commas.split(/,/);
}

export function toArrayOfIntegers(value: any): number[] {
  if (! Array.isArray(value)) {
    if (typeof value === 'string') {
      return toArrayOfIntegers(value.split(',').map(element => element.trim()));
    }

    throw new TypeError("Not an array of integers");
  }

  const output: number[] = [];

  for (const element of value) {
    if (typeof (element) !== 'number') {
      const parsed = Number(element);
      if (isNaN(parsed) || ! Number.isInteger(parsed)) {
        throw new TypeError("Element cannot be converted to an integer");
      }
      output.push(parsed);
    } else {
      output.push(element);
    }
  }

  return output;
}

export function toArrayOfNumbers(value: any): number[] {
  if (! Array.isArray(value)) {
    if (typeof value === 'string') {
      return toArrayOfNumbers(value.split(',').map(element => element.trim()));
    }

    throw new TypeError("Not an array of numbers");
  }

  const output: number[] = [];

  for (const element of value) {
    if (typeof (element) !== 'number') {
      const parsed = Number(element);
      if (isNaN(parsed)) {
        throw new TypeError("Element cannot be converted to a number");
      }
      output.push(parsed);
    } else {
      output.push(element);
    }
  }

  return output;
}

export function toArrayOfStrings(value: any): string[] {
  if (! Array.isArray(value)) {
    if (typeof value === 'string') {
      return toArrayOfStrings(value.split(',').map(element => element.trim()));
    }

    throw new TypeError("Not an array of strings");
  }

  const output: string[] = [];

  for (const element of value) {
    if (typeof (element) !== 'string') {
      output.push(`${element}`);
    } else {
      output.push(element);
    }
  }

  return output;
}

export function toArrayOfLines(value: any): string[] {
  if (! Array.isArray(value)) {
    if (typeof value === 'string') {
      return toArrayOfStrings(value.split(/[\r\n]+/).map(element => element.trim()));
    }

    throw new TypeError("Not an array of strings");
  }

  const output: string[] = [];

  for (const element of value) {
    if (typeof (element) !== 'string') {
      output.push(`${element}`);
    } else {
      output.push(element);
    }
  }

  return output;
}

/// Find an option in a react-select options array
export const findOptionByValue = <T extends any>(options: T[], value: string): T => {
  for (const option of options) {
    if (option.value === value) {
      return option;
    }
  }

  return { value: value, label: value } as any;
};

export const endsWithSlash = (s: string): boolean => {
  return s.lastIndexOf('/') === s.length - 1;
};

export const pathJoin = (part1: string, ...parts: string[]): string => {
  let output = part1;

  for (const part of parts) {
    if (endsWithSlash(output)) {
      if (part.indexOf('/') === 0) {
        output += part.substr(1);
      } else {
        output += part;
      }
    } else {
      if (part.indexOf('/') !== 0) {
        output += '/';
      }
      output += part;
    }
  }

  return output;
};

export const sleep = async (milliseconds: number): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), milliseconds);
  });
};
