import { useEffect, useState } from 'react';
import api from '../state/api';
import { UploadGroup, blankUploadGroup } from '../state/uploadGroupsReducer';
import { useUpdateListener } from '../updateListeners';

const PER_PAGE = 10;

interface FetchUploadGroupsResults {
  uploadGroups?: UploadGroup[];
  numberOfPages: number;
  numberOfUploadGroups?: number;
  totalNumberOfUploadGroups?: number;
};

interface FetchUploadGroupsProps {
  children: (results: FetchUploadGroupsResults) => any;
  page?: number;
  search?: string;
  query?: object;
};

export const FetchUploadGroups = ({ children, page, search, query }: FetchUploadGroupsProps): JSX.Element => {
  const [results, setResults] = useState<FetchUploadGroupsResults>({ numberOfPages: 0 });
  const lastRefreshed = useUpdateListener('upload-groups');

  useEffect(() => {
    let cancelled = false;

    api.service('upload-groups').find({
      query: {
        ...(query || {}),
        $admin: true,
        $search: search,
        $skip: (page || 0) * PER_PAGE,
        $limit: PER_PAGE,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setResults({
            totalNumberOfUploadGroups: json.total,
            numberOfPages: json.total / json.limit,
            numberOfUploadGroups: json.data.length,
            uploadGroups: json.data,
          });
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching uploadGroups: `, error);
        }
      });

    return () => { cancelled = true; };
  }, [page, search, query, lastRefreshed]);

  return children(results);
};


interface FetchUploadGroupProps {
  children: ({ uploadGroup }: { uploadGroup: UploadGroup }) => any;
  id?: number;
};

export const FetchUploadGroup = ({ children, id }: FetchUploadGroupProps): JSX.Element => {
  const [uploadGroup, setUploadGroup] = useState<UploadGroup>({ ...blankUploadGroup });
  const lastRefreshed = useUpdateListener('upload-groups');

  useEffect(() => {
    if (!id || !+id) {
      setUploadGroup(blankUploadGroup);
      return;
    }

    let cancelled = false;

    api.service('upload-groups').get(id, {
      query: {
        $admin: true,
      },
    })
      .then((json: any) => {
        if (!cancelled) {
          setUploadGroup(json);
        }
      })
      .catch((error: any) => {
        if (!cancelled) {
          console.error(`Error fetching uploadGroups: `, error);
        }
        setUploadGroup({ ...blankUploadGroup });
      });

    return () => { cancelled = true; };
  }, [id, lastRefreshed]);

  return children({ uploadGroup });
};

