import React, { Component } from 'react';
import TableScreen, { Column, TableFilter } from '../components/TableScreen';
import { FetchCohortFeedItems } from '../fetchers/FetchCohortFeedItems';
import { CohortFeedItem } from '../state/cohortFeedItemsReducer';
import CohortSelect from '../selects/CohortSelect';
import UserSelect from '../selects/UserSelect';


interface Props {
};

interface State {
  page: number;
  search: string;
  filterCohortId?: number;
  filterUserId?: number;
};

class CohortFeedItemListScreen extends Component<Props, State> {
  static title = "Cohort Feeds";
  columns: Column[];

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 0,
      search: '',
    };

    this.columns = [
      { title: "ID" },
      { title: "Cohort ID" },
      { title: "User" },
      { title: "Text" },
    ];
  }

  onSelectPage = (page: number) => {
    this.setState({ page });
  }

  onSearchChange = (search: string) => {
    this.setState({ search });
  }

  render() {
    return (
      <FetchCohortFeedItems
        page={this.state.page}
        search={this.state.search}
        query={{ 
          cohortId: this.state.filterCohortId || undefined,
          userId: this.state.filterUserId || undefined,
        }}
      >
        {({ cohortFeedItems, numberOfPages }) =>
          <TableScreen
            title={(this.constructor as any).title}
            singular="Cohort Feed Item"
            columns={this.columns}
            list={cohortFeedItems}
            row={(item: CohortFeedItem) => [
              item.id || '',
              item.cohortId || '',
              (item.user ? `${item.user.firstName || ''} ${item.user.lastName}` : item.userId) || '',
              item.text || ''
            ]}
            rowId={(item: CohortFeedItem) => `${item.id}`}
            detailPath="/cohortfeeditems/:id"
            currentPage={this.state.page}
            numberOfPages={numberOfPages}
            onSelectPage={this.onSelectPage}
            search={this.state.search}
            onSearchChange={this.onSearchChange}
            renderFilters={() =>
              <>
                <TableFilter label="Cohort" maxWidth={30}>
                  <CohortSelect
                    value={this.state.filterCohortId}
                    onChange={filterCohortId => this.setState({ filterCohortId })}
                  />
                </TableFilter>
                <TableFilter label="User" maxWidth={30}>
                  <UserSelect
                    value={this.state.filterUserId}
                    onChange={filterUserId => this.setState({ filterUserId })}
                  />
                </TableFilter>
              </>
            }
          />
        }
      </FetchCohortFeedItems>
    );
  }
}

export default CohortFeedItemListScreen;
