import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { FetchCohort } from '../fetchers/FetchCohorts';
import CourseSelect from '../selects/CourseSelect';
import { Cohort } from '../state/cohortsReducer';
import { deleteCohort, saveCohort } from '../state/cohortsActions';

interface ParamProps {
  cohortid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class CohortDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField, changeFieldValue }: FormCallbacks<Cohort>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="courseId">Course</Label>
              <CourseSelect
                id="courseId"
                value={getField('courseId')}
                onChange={newValue => changeFieldValue('courseId', null, newValue)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="accessCode">Access Code</Label>
              <Input type="text" name="accessCode" accessCode="accessCode" value={getField('accessCode')} onChange={event => changeField('accessCode', event)} />
              <FormText>
                Enter the word "new" (in lower case) to generate a new random code
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<Cohort>, original: Cohort, changes: Partial<Cohort>): Promise<Cohort> => {
    return await saveCohort(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<Cohort>, original: Cohort): Promise<void> => {
    return await deleteCohort(original);
  }

  render() {
    return (
      <FetchCohort id={+this.props.match.params.cohortid}>
        {({ cohort }) =>
          <DetailScreen<Cohort>
            original={cohort}
            isNew={this.props.match.params.cohortid === 'new'}
            titleField="id"
            titleIfNew="New Cohort"
            route={[{ 'Cohorts': '/cohorts' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchCohort>
    );
  }
}

export default CohortDetailScreen;
