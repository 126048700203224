import api from './api';
import { blankCohort, Cohort } from './cohortsReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveCohort(original: Cohort, changes: Partial<Cohort>): Promise<Cohort> {
  const cohort: Cohort = { ...blankCohort, ...original, ...changes };
  const params = {
    query: {
      $admin: true,
    },
  };

  const newRecord = cohort.id
    ? await api.service('cohorts').patch(cohort.id, changes, params)
    : await api.service('cohorts').create(cohort, params);
  notifyUpdateListeners('cohorts');
  return newRecord;
}

export async function deleteCohort(original: Cohort) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('cohorts').remove(original.id, params);
  notifyUpdateListeners('cohorts');
}

