import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DetailScreen, { FormCallbacks } from '../components/DetailScreen';
import { FetchAppSetting } from '../fetchers/FetchAppSettings';
import { AppSetting } from '../state/appSettingsReducer';
import { saveAppSetting, deleteAppSetting } from '../state/appSettingsActions';

interface ParamProps {
  appsettingid: string;
};

interface Props {

};

type CombinedProps = RouteComponentProps<ParamProps> & Props;

class AppSettingDetailScreen extends Component<CombinedProps> {
  renderForm = ({ getField, changeField }: FormCallbacks<AppSetting>) => {
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="key">Key</Label>
              <Input type="text" name="key" id="key" value={getField('key')} onChange={event => changeField('key', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="value">Value</Label>
              <Input type="text" name="value" id="value" value={getField('value')} onChange={event => changeField('value', event)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="createdAt">Created</Label>
              <Input readOnly type="text" name="createdAt" id="createdAt" value={getField('createdAt')} onChange={event => changeField('createdAt', event)} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="updatedAt">Updated</Label>
              <Input readOnly type="text" name="updatedAt" id="updatedAt" value={getField('updatedAt')} onChange={event => changeField('updatedAt', event)} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  onSave = async (_detailScreen: DetailScreen<AppSetting>, original: AppSetting, changes: Partial<AppSetting>): Promise<AppSetting> => {
    return await saveAppSetting(original, changes);
  }

  onDelete = async (_detailScreen: DetailScreen<AppSetting>, original: AppSetting): Promise<void> => {
    return await deleteAppSetting(original);
  }


  render() {
    return (
      <FetchAppSetting id={+this.props.match.params.appsettingid}>
        {({ appSetting }) =>
          <DetailScreen<AppSetting>
            original={appSetting}
            isNew={this.props.match.params.appsettingid === 'new'}
            titleField="id"
            titleIfNew="New App Setting"
            route={[{ 'App Settings': '/appsettings' }]}
            renderForm={this.renderForm}
            onSave={this.onSave}
            onDelete={this.onDelete}
          />
        }
      </FetchAppSetting>
    );
  }
}

export default AppSettingDetailScreen;
