import feathers from '@feathersjs/client';
import _ from 'lodash';
import querystring from 'querystring';
import api, { REST_ROOT } from './api';
import { blankUpload, Upload } from './uploadsReducer';
import { notifyUpdateListeners } from '../updateListeners';

export async function saveUpload(original: Upload, changes: Partial<Upload>): Promise<Upload> {
  const params: any = {
    query: {
      $admin: true,
    },
  };

  const upload: Upload = { ...blankUpload, ...original, ...changes };

  const form = new FormData();
  let haveFile = false;
  _.each(upload, (value, key) => {
    if (key === 'files') {
      if (value !== null && value.length > 0) {
        haveFile = true;
        form.append(key, value[0]);
      }
    } else if (key !== 'id') {
      form.append(key, value);
    }
  });

  if (!haveFile) {
    // If we're not uploading a file, send it over socket.io
    const newUpload = upload.id
      ? await api.service('uploads').patch(upload.id, changes, params)
      : await api.service('uploads').create(upload, params);

    notifyUpdateListeners('uploads');
    return newUpload;
  }

  // Otherwise, send it over multipart/form-data

  const { accessToken } = await api.get('authentication');

  const promise = new Promise<Upload>((resolve, reject) => {
    const request = new XMLHttpRequest();
    if (upload.id) {
      request.open('PATCH', `${REST_ROOT}/uploads/${upload.id}?${querystring.encode(params)}`);
    } else {
      request.open('POST', `${REST_ROOT}/uploads?${querystring.encode(params)}`);
    }
    request.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    request.setRequestHeader('Accept', `application/json`);
    request.addEventListener('progress', event => {
      console.log(`Update progress `, event.lengthComputable ? Math.floor(event.loaded / event.total * 100) : `unknown`);
    });
    request.addEventListener('load', _event => {
      let json: any;
      try {
        json = JSON.parse(request.responseText);
        if (Math.floor(request.status / 100) !== 2) {
          throw feathers.errors.convert(json) || new Error(json.message || "Upload failed (no error message)");
        }

        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
    request.addEventListener('error', _event => {
      reject(new Error("Upload failed"));
    });
    request.addEventListener('abort', _event => {
      reject(new Error("Upload aborted"));
    });
    request.send(form);
  });

  const newRecord = await promise;
  notifyUpdateListeners('uploads');
  return newRecord;
};

export async function deleteUpload(original: Upload) {
  const params = {
    query: {
      $admin: true,
    },
  };

  await api.service('uploads').remove(original.id, params);
  notifyUpdateListeners('uploads');
}
